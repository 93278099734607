import { useCallback, useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ROUTES } from '../../defs/routes';
import StepCommonInfo from './step_common_info';
import BackBar from '../../components/BackBar';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Modal from '../../components/Modal';
import { OrderContext, STEPS } from '../../store/OrderProvider';
import { UserContext } from '../../store/UserProvider';
import StepClientInfo from './step_client_info';
import StepPaymentBooking from './step_paymet_booking';
import ConfirmOrder from './confirm_order';
import Payment from './payment';
import Price, { CURRENCY } from '../../components/Price';
import InfoDescription from '../../components/InfoDescription';
import log from '../../utils/logger';
import StepPrint from './step_print';
import Stepper2 from '../../components/Stepper2';

const OrderForm = () => {
  let navigate = useNavigate();

  const {t} = useTranslation(['common', 'order']);

  const orderInfo = useContext(OrderContext);
  const userInfo = useContext(UserContext);

  orderInfo.useState('common_schedule', userInfo.getOrderSession().schedule || '');
  const [schedule] = orderInfo.useState('schedule');
  const [paymentOrder] = orderInfo.useState('payment_order', userInfo.getOrderSession().order_id || '');

  const handleNextStep = async () => {
    let errors = await orderInfo.validate(orderInfo.page);
    let isValid = errors.length === 0;

    if (isValid) {
      if (orderInfo.page === STEPS.client_info) {
        handleConfirmOrderShow();
      } else if (orderInfo.page === STEPS.payment_booking) {
        orderInfo.createOrder().then((order) => {
          userInfo.setOrderSession({order_id: order.id});
          handlePaymentShow();
        }).catch((e) => {
          log.error(e);
          orderInfo.addError('payment_errors', e.error, e.error, e.error);
        });
      } else {
        orderInfo.nextPage();
      }
    }
  };

  const handleConfirmOrder = async () => {
    orderInfo.nextPage();
    setShowConfirmOrder(false);
  };

  const handleCancel = () => {
    orderInfo.reset();
    navigate(ROUTES.EXCURSIONS, { replace: true });
  };

  const handleSelectStep = useCallback((idx) => {
    orderInfo.setPage(idx);
  }, []);

  const [showConfirmOrder, setShowConfirmOrder] = useState(false);
  const handleConfirmOrderClose = () => setShowConfirmOrder(false);
  const handleConfirmOrderShow = () => setShowConfirmOrder(true);

  const [showPayment, setShowPayment] = useState(false);
  const handlePaymentClose = () => {
    setShowPayment(false);
    orderInfo.reset();
    navigate(ROUTES.EXCURSIONS, { replace: true });
  }
  const handlePaymentShow = () => setShowPayment(true);

  useEffect(() => {
    if (paymentOrder) {
      orderInfo.setPage(STEPS.payment_booking, true);
    }
  }, [paymentOrder]);

  return (
    <>
      <div className="trip-app">
        <BackBar title={t('order:new_order.title')}>
          <Stepper2 steps={orderInfo.steps} activeStep={orderInfo.page} onSelect={handleSelectStep}></Stepper2>
        </BackBar>
        <div className="trip-app-body">
          <table>
            <tbody>
              <tr>
                <td>
                  <InfoDescription description={t('order:new_order.in_check')}>
                    <Price currency={CURRENCY.RUB} value={orderInfo.getPaymentAmount()} />
                  </InfoDescription>
                </td>
                <td>
                  <InfoDescription description={t('order:new_order.total')}>
                    <Price currency={CURRENCY.RUB} value={orderInfo.getTotalAmount()} />
                  </InfoDescription>
                </td>
                <td>
                  <InfoDescription description={t('order:new_order.seats')}>
                    {schedule ? orderInfo.getSeatNumbers().join(', ') : '-'}
                  </InfoDescription>
                </td>
              </tr>
            </tbody>
          </table>
          <hr/>
          {orderInfo.page === STEPS.common_info && <StepCommonInfo/>}
          {orderInfo.page === STEPS.client_info && <StepClientInfo/>}
          {orderInfo.page === STEPS.payment_booking && <StepPaymentBooking/>}
          {orderInfo.page === STEPS.print && <StepPrint/>}
        </div>
        <div className="trip-app-footer">
          <Button.Panel>
            <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handleNextStep}>{t('common:button.next')}</Button>
            <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handleCancel}>{t('common:button.cancel')}</Button>
          </Button.Panel>
        </div>
      </div>

      <Modal show={showConfirmOrder} onHide={handleConfirmOrderClose}>
        <Modal.Header>{t('order:confirm_order.title')}</Modal.Header>
        <Modal.Body>
          <ConfirmOrder/>
        </Modal.Body>
        <Modal.Footer>
          <Button.Panel>
            <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handleConfirmOrder}>{t('common:button.alright')}</Button>
            <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handleConfirmOrderClose}>{t('common:button.cancel')}</Button>
          </Button.Panel>
        </Modal.Footer>
      </Modal>

      <Modal show={showPayment} onHide={handlePaymentClose}>
        <Modal.Header>{t('order:payment.title')}. {t(`order:payment_booking.${userInfo.getOrderSession().payment_method}`)}</Modal.Header>
        <Modal.Body>
          <Payment type={userInfo.getOrderSession().payment_method} onCancel={handlePaymentClose} onSuccess={handlePaymentClose}/>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button.Panel>
            <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handlePayment}>{t('common:button.paid')}</Button>
            <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handlePaymentClose}>{t('common:button.cancel')}</Button>
          </Button.Panel> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

OrderForm.propTypes = {
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

export default OrderForm;
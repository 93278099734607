import dayjs from 'dayjs';
import { ClassTimestampsHelper } from '../ClassHelper';
import Account from '../account/Account';
import DynamicPrice from '../price/DynamicPrice';
import User from '../user/User';

class DiscountBase extends ClassTimestampsHelper {
  constructor({ type, title, account, cntActivations, startDate, endDate, discount, appliesTo, forGroups, users = [], status } = {}) {
    super(...arguments);
    this.type = type; // DiscountType
    this.title = title;
    this.account = this.ref(account, Account);
    this.cntActivations = cntActivations;
    this.startDate = this.obj(startDate, Date);
    this.endDate = this.obj(endDate, Date);
    this.discount = this.obj(discount, DynamicPrice);
    this.appliesTo = appliesTo; // AppliesTo
    this.forGroups = forGroups; // ForGroup
    this.users = this.array(users, User, true);
    this.status = status; // Status
  }

  isApplyTo({date}) {
    const granularity = 'day';
    const inclusive = '[]';
    const dCheck = dayjs(date);
    const dStart = dayjs(this.startDate);
    const dEnd = dayjs(this.endDate);
    const dAi = inclusive[0] === '(';
    const dBi = inclusive[1] === ')';

    return ((dAi ? dCheck.isAfter(dStart, granularity) : !dCheck.isBefore(dStart, granularity)) &&
            (dBi ? dCheck.isBefore(dEnd, granularity) : !dCheck.isAfter(dEnd, granularity)))
        || ((dAi ? dCheck.isBefore(dStart, granularity) : !dCheck.isAfter(dStart, granularity)) &&
            (dBi ? dCheck.isAfter(dEnd, granularity) : !dCheck.isBefore(dEnd, granularity)));
  }
}

export default DiscountBase;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import ArrowLeftIcon from './icons/ArrowLeft';

const BackBar = ({ title, children, onBack }) => {
  let navigate = useNavigate();

  return (
    <>
      <div className="trip-app-header">
        <ArrowLeftIcon onClick={typeof onBack === 'function' ? onBack : (() => navigate(-1))} />
        <div className="title">
          {title}
        </div>
        {children}
      </div>
    </>
  );
};

BackBar.propTypes = {
  title: PropTypes.string,
  onBack: PropTypes.func
};

export default BackBar;
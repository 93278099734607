import ClassHelper from '../ClassHelper';
import DynamicPrice from '../price/DynamicPrice';
import Price from '../price/Price';

class ExcursionPrice extends ClassHelper {
  constructor({ price, amount, reward, minAge, maxAge, noSeat, partialValue, rewardPartner } = {}) {
    super(...arguments);
    this.price = this.ref(price, Price);
    this.amount = amount;
    this.reward = this.obj(reward, DynamicPrice);
    this.minAge = minAge;
    this.maxAge = maxAge;
    this.noSeat = noSeat;
    this.partialValue = partialValue;
    this.rewardPartner = rewardPartner;
  }
}

export default ExcursionPrice;
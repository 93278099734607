import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export const ALIGN_TABLE = {
  RIGHT: 'right',
  LEFT: 'left',
  CENTER: 'center'
};

export const VERTICAL_ALIGN = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom'
};

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.style = props.cols ? 'table-fix' : 'table-flex';
  }

  render() {
    return (
      <div className={`trip-table ${this.style} ${this.props.className || ''} ${this.props.cols ? `table-cols-${this.props.cols}` : ''}`}>
        {this.props.cols ?
          this.props.children :
          this.props.children instanceof Array ?
            this.props.children.map((child, i) => [child, <Table.Row key={i} className="table-break" />]) :
            <>
              {this.props.children}
              <Table.Row className="table-break" />
            </>
          }
      </div>
    );
  }
}

Table.propTypes = {
  className: PropTypes.string,
  cols: PropTypes.number
};

const Row = ({ className = '', delim = false, style, onClick, children }) => {
  return (
    <div className={`table-row ${delim ? 'delim' : ''} ${className}`} style={style} onClick={onClick}>
      {children}
    </div>
  );
};

Row.propTypes = {
  className: PropTypes.string,
  delim: PropTypes.bool,
  style: PropTypes.any,
  onClick: PropTypes.func
};

Table.Row = Row;

const Cell = ({ className = '', colspan = 0, align = ALIGN_TABLE.LEFT, verticalAlign = VERTICAL_ALIGN.TOP, style, onClick, children }) => {
  return (
    <div className={`table-col table-cell align-${align} v-align-${verticalAlign} ${className}${colspan > 1 ? ` colspan-${colspan}` : ''}`} style={style} onClick={onClick}>
      {children}
    </div>
  );
};

Cell.propTypes = {
  className: PropTypes.string,
  colspan: PropTypes.number,
  align: PropTypes.oneOf(Object.values(ALIGN_TABLE)),
  verticalAlign: PropTypes.string,
  style: PropTypes.any,
  onClick: PropTypes.func
};

Table.Cell = Cell;

export default Table;
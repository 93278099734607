import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import { ru } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';
import { RepositoryContext } from '../../store/RepositoryProvider';
import { UserContext } from '../../store/UserProvider';
import { OrderContext } from '../../store/OrderProvider';
import Table from '../../components/Table';
import API from '../../server/api';

const getDateFormat = (date) => {
  return date ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : '';
};

const SelectDate = ({ onSelect, onCancel, onApply }) => {
  const {t} = useTranslation(['common', 'order']);

  const userInfo = useContext(UserContext);
  const orderInfo = useContext(OrderContext);
  const repository = useContext(RepositoryContext);

  const [seats, setSeats] = useState({});
  const [time, setTime] = useState(orderInfo.getOrderInfo().common_time);
  const [date, setDate] = useState(new Date(orderInfo.getOrderInfo().common_date));
  const [appointedDate, setAppointedDate] = useState({ time: orderInfo.getOrderInfo().common_time, date: orderInfo.getOrderInfo().common_date });

  const handleSelectDate = (day) => {
    setDate(day);
    let _date = getDateFormat(day);
    repository.list(API.schedule.times, { id: orderInfo.getOrderInfo().common_excursion, date: _date, accountId: userInfo.getSession().account }, Object).then((times) => {
      setSeats((prevState) => {
        prevState[_date] = {
          seats: prevState[_date] ? prevState[_date].seats : 0,
          times: times.map((time) => ({ seat: time.limit.value - time.limit.cntSeats, time: time.time }))
        };
        return prevState;
      });
    }).catch((e) => {});

    if (day) {
      typeof onSelect === 'function' && onSelect(day);
    }
  };


  const formatDay = useCallback((day) => {
    const date = day.getDate();

    return (
      <div className={'calendar-day' + (seats[getDateFormat(day)] ? ' have-seats' : '')}>
        <div className="calendar-date">{date}</div>
        {seats[getDateFormat(day)] && (
          <div className="calendar-seat">
            {seats[getDateFormat(day)].seats}
          </div>
        )}
      </div>
    );
  }, [seats]);

  const handleApplyDate = useCallback(() => {
    typeof onApply === 'function' && onApply(appointedDate.date, appointedDate.time);
  }, [time, date]);

  const handleCancel = useCallback(() => {
    typeof onCancel === 'function' && onCancel();
  }, []);

  const handleTime = useCallback((time) => {
    setTime(time);
    setAppointedDate({ time, date });
  }, [time, date]);


  useEffect(() => {
    repository.request(API.schedule.datesSeats, { accountId: userInfo.getSession().account, id: orderInfo.getOrderInfo().common_excursion, subdivision: userInfo.getSession().subdivision }, Object).then((dates) => {
      Object.keys(dates).forEach((date) => {
        setSeats((prevState) => {
          prevState[date] = {
            seats: dates[date],
            times: []
          };
          return prevState;
        });
      });
    }).catch((e) => {
      setSeats({});
    });
  }, []);

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        <DayPicker mode="single" locale={ru} formatters={{ formatDay }} selected={date} onSelect={handleSelectDate} footer={date && seats[getDateFormat(date)] && (
          <p>
            <Table cols={2} className="toggle-list">
              <Table.Row>
                {seats[getDateFormat(date)].times.map((_time) => (
                  <Table.Cell key={_time.time}>
                    <label key={_time.time} className="toggle small">
                      {_time.time} - {t('common:place.place', { count: _time.seat })}
                      <input type="checkbox" defaultChecked={appointedDate.time === _time.time && appointedDate.date === _time.date} value={_time.time} onChange={(e) => { handleTime(_time.time, e.target.checked); }}/>
                    </label>
                  </Table.Cell>
                ))}
              </Table.Row>
            </Table>
          </p>
        )}/>
      </div>
      <div>
        <Button.Panel>
          <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.LEFT} onClick={handleApplyDate}>{t('common:button.save')}</Button>
          <Button variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.RIGHT} onClick={handleCancel}>{t('common:button.cancel')}</Button>
        </Button.Panel>
      </div>
    </div>
  );
}

SelectDate.propTypes = {
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
};

export default SelectDate;
import { useState } from 'react';
import PropTypes from 'prop-types';
import ErrorHint from './ErrorHint';
import ShowIcon from './icons/Show';
import HideIcon from './icons/Hide';

const InputWithError = ({ label, type = 'text', value = '', id, placeholder, name, className = '', onChange, readOnly = false, onEnter, error }) => {
  const [inputType, setInputType] = useState(type);

  return (
    <div className={`${className}`}>
      {label && (
        <label className="field-label">{label}</label>
      )}
      <div className={type === 'password' ? 'input-group' : 'input'}>
        <input className="text form-control" type={inputType} value={value} id={id} name={name} placeholder={placeholder} readOnly={readOnly}
          onChange={(e) => {
            (typeof onChange === 'function') && onChange(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && typeof onEnter === 'function') {
              e && e.preventDefault();
              onEnter(e.target.value);
            }
          }}
        />
        {(type === 'password') && (
          <span className="input-group-text" onClick={() => setInputType(inputType === 'text' ? 'password' : 'text')}>
            {(inputType === 'password') ? <ShowIcon /> : <HideIcon /> }
          </span>
        )}
      </div>
      {error && (
        <ErrorHint error={error} />
      )}
    </div>
  );
};

InputWithError.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  error: PropTypes.any,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
};

export default InputWithError;

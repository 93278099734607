export const REF_STATUS = {
  FULFILLED: 'fulfilled',
  PENDING: 'pending',
  REJECTED: 'rejected',
  NONE: 'none'
};

export default class ClassHelper {
  constructor(props = {}) {
    this.id = props._id || props.id;
    this._isRef = false;
    this._state = REF_STATUS.NONE;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }

  ref(data, clazz = null) {
    let obj = data;
    if (clazz && typeof clazz.constructor === 'function') {
      if (data instanceof clazz) {
        obj = data;
        obj._isRef = false;
        obj._state = data._state || REF_STATUS.FULFILLED;
        obj._clazz = clazz;
      } else if (data === null || data === undefined) {
        obj = null;
      } else if (typeof data === 'string') {
        obj = new clazz({ id: data });
        obj._isRef = true;
        obj._state = REF_STATUS.NONE;
        obj._clazz = clazz;
      } else {
        obj = new clazz(data);
        obj._isRef = false;
        obj._state = REF_STATUS.NONE;
        obj._clazz = clazz;
      }
    } else {
      obj = data;
    }
    return obj;
  }

  array(list, clazz = null, isRef = false) {
    let _list = list;
    let _new_list = [];
    if (!(_list instanceof Array)) {
      _list = _list ? [_list] : [];
    }
    _list.forEach((data) => {
      _new_list.push(isRef ? this.ref(data, clazz) : this.obj(data, clazz));
    });
    return _new_list;
  }

  obj(data, clazz = null) {
    if (!clazz && typeof data !== 'object') {
      return data;
    } else if (clazz && typeof clazz.constructor === 'function') {
      if (clazz === String) {
        return data === null || data === undefined ? null : String(data);
      } else if (data instanceof clazz) {
        return data;
      } else if (data === null || data === undefined) {
        return null;
      } else {
        return new clazz(data);
      }
    } else {
      return data;
    }
  }

  map(map, clazz = null, isRef = false) {
    let _map = map;
    let _new_map = {};

    if (!typeof _map === 'object' || !_map) {
      _map = {};
    }

    for (let key in _map) {
      _new_map[key] = isRef ? this.ref(_map[key], clazz) : this.obj(_map[key], clazz);
    }

    return _new_map;
  }

  isPending = () => {
    return this._isRef && this._state === REF_STATUS.PENDING;
  }
  isFulfilled() {
    return !this._isRef || this._state === REF_STATUS.FULFILLED;
  }
  isRejected() {
    return this._isRef && this._state === REF_STATUS.REJECTED;
  }
  isRequested() {
    return this._isRef && this._state === REF_STATUS.NONE;
  }
  isRef() {
    return this._isRef;
  }
}

export class ClassTimestampsHelper extends ClassHelper {
  constructor(props = {}) {
    super(...arguments);
    this.createdAt = this.obj(props.createdAt, Date);
    this.updatedAt = this.obj(props.updatedAt, Date);
    this.deletedAt = this.obj(props.deletedAt, Date);
  }
}

import { useState, createContext, useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from './UserProvider';
import { STEPPER_ITEM_STATUS } from '../components/Stepper';
import { APICode, DynamicPriceType, ForGroup, PriceType, SellerType } from '../models/types';
import useAPI from '../hooks/useAPI';
import API from '../server/api';
import Message from '../models/Message';
import Order from '../models/order/Order';
import { RepositoryContext } from './RepositoryProvider';
import { ALERT_TYPE } from '../components/Alert';
import ReportParams from '../models/report/ReportParams';
import UserAccount from '../models/user/UserAccount';
import Report from '../models/report/Report';
import User from '../models/user/User';
import { Step } from '../components/Stepper2';

export const OrderContext = createContext();

export const STEPS = {
  common_info: 0,
  client_info: 1,
  payment_booking: 2,
  print: 3
};

const initialState = {
  schedule: null,
  excursion: null,
  common_excursion: null,
  common_schedule: null,
  common_subdivision: null,
  common_date: null,
  common_passengers: [],
  common_passports: [],
  common_wishes: [],
  common_place: null,
  common_put_passport: true,
  common_errors: [],
  client_phone: '',
  client_contact_phones: [],
  client_name: '',
  client_first_name: '',
  client_last_name: '',
  client_middle_name: '',
  client_birthday: '',
  client_discount: null,
  client_user: '',
  client_errors: [],
  payment_method: null,
  payment_comment: null,
  payment_order: null,
  payment_errors: [],
  payment: {},
  order: null,
  steps: []
};

const initSteps = (t) => {
  return Object.keys(STEPS).map((step) => new Step({ title: t(`order:steps.${step}`), id: ~~(Math.random() * 10000) }));
};

export const OrderProvider = (props) => {
  const {t} = useTranslation(['common', 'order']);

  const [page, setPage] = useState(0);
  const [orderInfo, setOrderInfo] = useState(initialState);
  const [prices, setPrices] = useState([]);
  const [steps, setSteps] = useState(initSteps(t));
  const [updated, setUpdated] = useState(initSteps(t));

  const { callApi } = useAPI();

  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const update = () => {
    setUpdated(new Date().getTime());
  };

  const nextPage = () => {
    let newPage = page + 1;

    if (newPage <= steps.length && newPage >= 0) {
      steps[page].complete();

      if (newPage < steps.length) {
        steps[newPage].select();
        setPage(newPage);
      }
    }
  };

  const prevPage = () => {
    let newPage = page - 1;

    if (newPage < steps.length && newPage >= 0) {
      setPage(newPage);
    }
  };

  const selectPage = (idx, allComplete) => {
    if (idx < steps.length && idx >= 0) {
      if (allComplete) {
        for (let p = 0; p <= idx; ++p) {
          steps[p].complete();
        }
      }

      setPage(idx);
    }
  };

  const reset = () => {
    setSteps(initSteps(t));
    setPage(0);
    setOrderInfo(initialState);
    update();
  };

  const _setState = (field, value) => {
    setOrderInfo((prevState) => ({ ...prevState, [field]: value }));
  };

  const _useState = (...args) => {
    const [field] = args;

    useEffect(() => {
      if (args.length > 1) {
        _setState(field, args[1]);
      } else {
        _setState(field, orderInfo[field]);
      }
    }, []);

    return [orderInfo[field], (value) => _setState(field, value)];
  };

  const setOrder = (order) => {
    _setState('order', order);
  };

  const getOrder = () => {
    return orderInfo.order;
  };

  const getOrderInfo = () => {
    return {
      common_excursion: orderInfo.common_excursion,
      common_schedule: orderInfo.common_schedule,
      common_subdivision: orderInfo.common_subdivision,
      common_date: orderInfo.common_date,
      common_passengers: orderInfo.common_passengers,
      common_passports: orderInfo.common_passports,
      common_wishes: orderInfo.common_wishes,
      common_place: orderInfo.common_place,
      common_put_passport: orderInfo.common_put_passport,
      common_errors: orderInfo.common_errors,
      client_phone: orderInfo.client_phone,
      client_contact_phones: orderInfo.client_contact_phones,
      client_name: orderInfo.client_name,
      client_first_name: orderInfo.client_first_name,
      client_last_name: orderInfo.client_last_name,
      client_middle_name: orderInfo.client_middle_name,
      client_birthday: orderInfo.client_birthday,
      client_discount: orderInfo.client_discount,
      client_user: orderInfo.client_user,
      client_errors: orderInfo.client_errors,
      payment_method: orderInfo.payment_method,
      payment_comment: orderInfo.payment_comment,
      payment_order: orderInfo.payment_order,
      payment_errors: orderInfo.payment_errors,
      excursion: orderInfo.excursion,
      schedule: orderInfo.schedule,
      order: orderInfo.order,
    };
  };

  const getPaymentAmount = () => {
    let amount = orderInfo.common_passengers.reduce((amount, passenger) => {
      let price = prices.find((price) => price.price.id === passenger.price) || { amount: 0 };
      let expectedAmount = price.amount;

      if (orderInfo.client_discount) {
        let needCount = orderInfo.client_discount.forGroups === ForGroup.ALL;
        needCount = needCount || (orderInfo.client_discount.forGroups === ForGroup.ADULTS && price.price.priceType === PriceType.NORMAL);
        needCount = needCount || (orderInfo.client_discount.forGroups === ForGroup.CHILDREN && price.price.priceType === PriceType.CHILD);

        if (needCount) {
          expectedAmount = (orderInfo.client_discount.type === DynamicPriceType.ABSOLUTE ?
            (price.amount - orderInfo.client_discount.value) :
            (price.amount * (1 - orderInfo.client_discount.value / 100).toFixed(2))
          );
        }
      }

      return amount + passenger.count * expectedAmount;
    }, 0);

    return amount;
  };  

  const getSeatNumbers = () => {
    let seats = [];

    if (orderInfo.order) {
      seats = orderInfo.seats.map((seat) => seat.seats).reduce((seats, seat) => seats.concat(seat), []);
    } else {
      let placeNumber = orderInfo.schedule.stat.totalSeats + 1;
      seats = orderInfo.common_passengers.reduce((seats, passenger) => {
        let price = prices.find((price) => price.price.id === passenger.price) || { amount: 0 };

        if (!price.noSeat) {
          seats.push(placeNumber++);
        }

        return seats;
      }, []);
    }

    return seats;
  };  

  const getTotalAmount = () => {
    return orderInfo.common_passengers.reduce((amount, passenger) => {
      let price = prices.find((price) => price.price.id === passenger.price) || { amount: 0 };
      return amount + passenger.count * price.amount;
    }, 0);
  };

  const addError = (field, code, title, message) => {
    setOrderInfo((prevState) => ({ ...prevState, [field]: orderInfo[field].push( new Message({ code, title, message }) ) }));
    update();
  };

  const clearError = (field, uid) => {
    if (uid) {
      setOrderInfo((prevState) => { let idx = prevState[field].findIndex(error => error.uid === uid); if (idx >= 0) prevState[field].splice(idx, 1); return prevState; });
    } else {
      setOrderInfo((prevState) => { prevState[field] = []; return prevState; });
    }
    update();
  };

  const validate = async (step) => {
    switch(step) {
      case STEPS.common_info: {
        orderInfo.common_errors = [];
        if (!orderInfo.common_excursion) {
          orderInfo.common_errors.push(new Message({ code: 'excursion_not_set', title: t('order:common_info.error.excursion_not_set'), type: ALERT_TYPE.ERROR }));
        } else {
          let excursion = await repository.getModel('Excursion', orderInfo.common_excursion);

          if (excursion && excursion.passportRequired &&
            orderInfo.common_put_passport && !(orderInfo.common_passengers.reduce((count, passenger) => count + passenger.passports.reduce((count, passport) => count + (passport && passport.fullName && passport.number ? 1 : 0), 0), 0) === orderInfo.common_passengers.reduce((count, passenger) => count + passenger.count, 0))
          ) {
            orderInfo.common_errors.push(new Message({ code: 'passports_not_set', title: t('order:common_info.error.passports_not_set'), type: ALERT_TYPE.ERROR }));
          }
        }
        if (!orderInfo.common_passengers.length) {
          orderInfo.common_errors.push(new Message({ code: 'passengers_not_set', title: t('order:common_info.error.passengers_not_set'), type: ALERT_TYPE.ERROR }));
        }
        if (!orderInfo.common_date) {
          orderInfo.common_errors.push(new Message({ code: 'date_not_set', title: t('order:common_info.error.date_not_set'), type: ALERT_TYPE.ERROR }));
        }
        if (!orderInfo.common_time) {
          orderInfo.common_errors.push(new Message({ code: 'time_not_set', title: t('order:common_info.error.time_not_set'), type: ALERT_TYPE.ERROR }));
        }
        if (!orderInfo.common_place) {
          orderInfo.common_errors.push(new Message({ code: 'place_not_set', title: t('order:common_info.error.place_not_set'), type: ALERT_TYPE.ERROR }));
        }
        setOrderInfo(orderInfo);

        return orderInfo.common_errors;
      }
      case STEPS.client_info: {
        orderInfo.client_errors = [];
        return orderInfo.client_errors;
      }
      case STEPS.payment_booking: {
        orderInfo.payment_errors = [];
        return orderInfo.payment_errors;
      }
      default: {}
    }
    return [];
  };


  const createOrder = () => {
    return new Promise(async (resolve, reject) => {
      let subdivision = userInfo.getSession().subdivision;
      let passports = [];

      orderInfo.common_passengers.forEach((passenger) => {
        Object.values(passenger.passports).forEach((passport) => {
          passports.push({ number: passport.number, fullName: passport.fullName, rewardPartner: passenger.rewardPartner || 0 });
        });
      });

      if (subdivision) {
        const response = await callApi(API.order.createOrder, {
          user: orderInfo.client_user,
          userInfo: {
            phone: orderInfo.client_phone,
            name: `${orderInfo.client_first_name} ${orderInfo.client_last_name} ${orderInfo.client_middle_name}`.trim()
          },
          excursion: orderInfo.common_excursion,
          subdivision: orderInfo.common_subdivision,
          schedule: orderInfo.common_schedule,
          passengers: orderInfo.common_passengers.map((passenger) => ({ price: passenger.price, count: passenger.count, ages: Object.values(passenger.ages) || [], rewardPartner: passenger.rewardPartner })),
          sellerType: SellerType.CASHIER,
          pointId: userInfo.getSession().point,
          paymentMethod: orderInfo.payment_method,
          contactPhones: [ ...orderInfo.client_contact_phones ],
          wishes: orderInfo.common_wishes,
          place: orderInfo.common_place,
          // address: orderInfo.client_info.place.address,
          passports: passports,
          comment: orderInfo.payment_comment,
          discountId: orderInfo.client_discount ? orderInfo.client_discount.id : ''
        });
        // - `user` - покупатель заказа
        // - `excursion` - ID экскурсии, которую заказывает пользователь
        // - `subdivision` - ID подразделения, к которому относится заказ
        // - `schedule` - ID расписания
        // - `passengers` - массив пассажиров (см. ниже)
        // - `sellerType` - тип продавца (см. ниже)
        // - `pointId` - ID точки посадки (обязателен если sellerType = cashier)
        // - `paymentMethod` - метод платежа (см. ниже)
        // - `contactPhones` - массив телефонов для связи - **необязательный параметр**
        // - `wishes` - массив пожеланий (см. ниже) - **необязательный параметр**
        // - `place` - ID места посадки (см. ниже) - **необязательный параметр**
        // - `address` - адрес посадки - **необязательный параметр**
        // - `passports` - массив паспортов пассажиров (см. ниже) - **необязательный параметр**
        // - `comment` - комментарий к заказу - **необязательный параметр**
        // - `discountId` - ID скидки / промокода - **необязательный параметр**

        if (response.error) {
          console.error('New Order error:', response);
          return reject(response);
        } else {
          resolve(new Order(response.data));
        }
      } else {
        console.error('New Order subdivision_is_null');
        reject({ error: 'subdivision_is_null' });
      }
    });
  };

  const isNewPhone = async () => {
    if (orderInfo.client_phone) {
      let response = await callApi(API.auth.checkPhone, { phone: orderInfo.client_phone });
      return response.data === APICode.USER_NOT_FOUND;
    }
    return false;
  };

  const inviteUser = async () => {
    if (orderInfo.client_phone) {
      let response = await callApi(API.order.createClient, {
        phone: orderInfo.client_phone,
        firstName: orderInfo.client_first_name,
        lastName: orderInfo.client_last_name,
        middleName: orderInfo.client_middle_name,
        birthday: orderInfo.client_birthday
      });
      if (response.error) {
        return response;
      } else {
        let user = new User(response.data);
        return user;
      }
    }

    return false;
  };

  const getUserAccount = async () => {
    if (orderInfo.client_phone) {
      let response = await callApi(API.account.users, new ReportParams({ filters: { phone: orderInfo.client_phone } }));
      if (response.error) {
        return response;
      } else {
        let report = new Report(response.data, UserAccount);
        let userAccountList = report.rows;
        let account = null;
        if (userAccountList.length) {
          account = userAccountList[0];
        }
        return account;
      }
    }
    return false;
  };

  const askUser = async (accountId) => {
    if (accountId) {
      let response = await callApi(API.user.user, { id: accountId });
      if (response.error) {
        return response;
      } else {
        let user = new User(response.data);
        return user;
      }
    }
    return false;
  };

  return (
    <OrderContext.Provider
      value={{
        page,
        steps,
        nextPage,
        prevPage,
        reset,
        setPage: selectPage,

        useState: _useState,

        setOrder,
        getOrder,
        getOrderInfo,

        addError,
        clearError,

        getPaymentAmount,
        getTotalAmount,
        getSeatNumbers,

        validate,
        prices,
        setPrices,

        createOrder,
        isNewPhone,
        inviteUser,
        getUserAccount,
        askUser
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from './Button';
import { ru } from 'date-fns/locale';
import { isSameDay } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import Modal from './Modal';
import DatePeriod from './DatePeriod';
import DateFormat from './DateFormat';
import EraseIcon from './icons/Erase';


export const DAY_PICKER_MODE = {
  RANGE: 'range',
  SINGLE: 'single',
  MULTIPLE: 'multiple'
};

export const APPLY_MODE = {
  BUTTON: 'button',
  AUTO: 'auto'
};

const SelectDate = ({ mode = DAY_PICKER_MODE.DEFAULT, apply = APPLY_MODE.BUTTON, className, selected, footer, readOnly = false, erase = false, onSelect, onCancel, onChange, fromYear, toYear }) => {
  const {t} = useTranslation(['common']);

  const [dates, setDates] = useState(selected); //Array<Date>, { from: Date, to: Date }, Date
  
  const [showSelectDate, setShowSelectDate] = useState(false);
  const handleSelectDateClose = () => setShowSelectDate(false);
  const handleSelectDateShow = () => setShowSelectDate(true);

  const handleDayClick = (day, modifiers) => {
    if (mode === DAY_PICKER_MODE.MULTIPLE) {
      const newDate = [...dates];
      if (modifiers.selected) {
        const index = dates.findIndex((d) => isSameDay(day, d));
        newDate.splice(index, 1);
      } else {
        newDate.push(day);
      }
      setDates(newDate);
    } else if (mode === DAY_PICKER_MODE.SINGLE) {}
  };

  useEffect(() => {
    setDates(selected);
  }, [selected]);

  const handleSelect = (dates) => {
    if (dates) {
      typeof onSelect === 'function' && onSelect(dates);
    }
    setDates(dates);
    if (apply === APPLY_MODE.AUTO) {
      setTimeout(() => {
        handleSelectDateClose();
        typeof onChange === 'function' && onChange(dates);
      }, 300);
    }
  };

  const handleApplyDate = useCallback(() => {
    handleSelectDateClose();
    if (!dates) {
      let defaultDates = null;
      if (mode === DAY_PICKER_MODE.RANGE) {
        defaultDates = { from: new Date(), to: new Date() };
      } else if (mode === DAY_PICKER_MODE.SINGLE) {
        defaultDates = new Date();
      } else if (mode === DAY_PICKER_MODE.MULTIPLE) {
        defaultDates = [new Date()];
      }
      setDates(defaultDates);
      typeof onChange === 'function' && onChange(defaultDates);
    } else {
      typeof onChange === 'function' && onChange(dates);
    }
  }, [dates]);

  const handleCancel = useCallback(() => {
    handleSelectDateClose();
    typeof onCancel === 'function' && onCancel();
  }, []);

  const handleErase = useCallback((e) => {
    e.stopPropagation();
    let dates = null;
    if (mode === DAY_PICKER_MODE.RANGE) {
      dates = { from: null, to: null };
    } else if (mode === DAY_PICKER_MODE.SINGLE) {
      dates = null;
    } else if (mode === DAY_PICKER_MODE.MULTIPLE) {
      dates = [];
    }
    setDates(dates);
    typeof onChange === 'function' && onChange(dates);
  }, [dates]);

  return (
    <>
      <div className={className}>
        <div className="input-group" onClick={() => !readOnly && handleSelectDateShow()}>
          {(mode === DAY_PICKER_MODE.RANGE) && (
            <DatePeriod className="form-control" start={dates && dates.from} end={dates && dates.to} />
          )}
          {(mode === DAY_PICKER_MODE.SINGLE) && (
            <DatePeriod className="form-control" start={dates} end={dates}/>
          )}
          {(mode === DAY_PICKER_MODE.MULTIPLE) && (
            <div className="form-control" start={dates}>
              {dates && dates.map((date) => (
                <span style={{paddingRight: '10px'}}><DateFormat.Day date={date} /></span>
              ))}
            </div>
          )}
          {erase && (
            <div className="input-group-text" onClick={handleErase}>
              <EraseIcon />
            </div>
          )}
        </div>
      </div>

      <Modal show={showSelectDate} onHide={handleSelectDateClose}>
        <Modal.Header>{t('boarding:select_date.title')}</Modal.Header>
        <Modal.Body>
          {showSelectDate && (
            <div className="trip-app">
              <div className="trip-app-body" style={{ margin: 'auto' }}>
                <DayPicker
                  captionLayout="dropdown"
                  fromYear={fromYear}
                  toYear={toYear}
                  defaultMonth={(mode === DAY_PICKER_MODE.SINGLE ? dates : mode === DAY_PICKER_MODE.MULTIPLE ? (dates && dates.length > 0 && dates[0]) : (dates && dates.start)) || new Date()}
                  mode={mode}
                  locale={ru}
                  selected={dates}
                  onSelect={handleSelect}
                  onDayClick={handleDayClick}
                  footer={footer}
                />
              </div>
              <div>
                <Button.Panel>
                  {(apply === APPLY_MODE.BUTTON) && (
                    <Button variant={BUTTON_TYPE.PRIMARY} position={BUTTON_POSITION.LEFT} onClick={handleApplyDate}>{t('common:button.save')}</Button>
                  )}
                  <Button variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.RIGHT} onClick={handleCancel}>{t('common:button.cancel')}</Button>
                </Button.Panel>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

SelectDate.propTypes = {
  mode: PropTypes.string,
  apply: PropTypes.string,
  className: PropTypes.string,
  selected: PropTypes.any,
  footer: PropTypes.any,
  erase: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  fromYear: PropTypes.any,
  toYear: PropTypes.any
};

export default SelectDate;
import PropTypes from 'prop-types';

const Cash = ({ className = '', onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`icon ${className}`} viewBox="0 0 24 24" onClick={onClick}>
      <path d="M21 19.75H7C6.53668 19.7474 6.09309 19.5622 5.76546 19.2345C5.43784 18.9069 5.25263 18.4633 5.25 18V10C5.25263 9.53668 5.43784 9.09309 5.76546 8.76546C6.09309 8.43784 6.53668 8.25263 7 8.25H21C21.4633 8.25263 21.9069 8.43784 22.2345 8.76546C22.5622 9.09309 22.7474 9.53668 22.75 10V18C22.7474 18.4633 22.5622 18.9069 22.2345 19.2345C21.9069 19.5622 21.4633 19.7474 21 19.75ZM7 9.75C6.9337 9.75 6.87011 9.77634 6.82322 9.82322C6.77634 9.87011 6.75 9.9337 6.75 10V18C6.75 18.0663 6.77634 18.1299 6.82322 18.1768C6.87011 18.2237 6.9337 18.25 7 18.25H21C21.0663 18.25 21.1299 18.2237 21.1768 18.1768C21.2237 18.1299 21.25 18.0663 21.25 18V10C21.25 9.9337 21.2237 9.87011 21.1768 9.82322C21.1299 9.77634 21.0663 9.75 21 9.75H7Z"/>
      <path d="M6 15.75H3C2.53668 15.7474 2.09309 15.5622 1.76546 15.2345C1.43784 14.9069 1.25263 14.4633 1.25 14V6C1.25263 5.53668 1.43784 5.09309 1.76546 4.76546C2.09309 4.43784 2.53668 4.25263 3 4.25H17C17.4633 4.25263 17.9069 4.43784 18.2345 4.76546C18.5622 5.09309 18.7474 5.53668 18.75 6V9C18.75 9.19891 18.671 9.38968 18.5303 9.53033C18.3897 9.67098 18.1989 9.75 18 9.75C17.8011 9.75 17.6103 9.67098 17.4697 9.53033C17.329 9.38968 17.25 9.19891 17.25 9V6C17.25 5.9337 17.2237 5.87011 17.1768 5.82322C17.1299 5.77634 17.0663 5.75 17 5.75H3C2.9337 5.75 2.87011 5.77634 2.82322 5.82322C2.77634 5.87011 2.75 5.9337 2.75 6V14C2.75 14.0663 2.77634 14.1299 2.82322 14.1768C2.87011 14.2237 2.9337 14.25 3 14.25H6C6.19891 14.25 6.38968 14.329 6.53033 14.4697C6.67098 14.6103 6.75 14.8011 6.75 15C6.75 15.1989 6.67098 15.3897 6.53033 15.5303C6.38968 15.671 6.19891 15.75 6 15.75Z"/>
      <path d="M14 16.75C13.4561 16.75 12.9244 16.5887 12.4722 16.2865C12.0199 15.9844 11.6675 15.5549 11.4593 15.0524C11.2512 14.5499 11.1967 13.997 11.3028 13.4635C11.409 12.9301 11.6709 12.4401 12.0555 12.0555C12.4401 11.6709 12.9301 11.409 13.4635 11.3028C13.997 11.1967 14.5499 11.2512 15.0524 11.4593C15.5549 11.6675 15.9844 12.0199 16.2865 12.4722C16.5887 12.9244 16.75 13.4561 16.75 14C16.75 14.7293 16.4603 15.4288 15.9445 15.9445C15.4288 16.4603 14.7293 16.75 14 16.75ZM14 12.75C13.7528 12.75 13.5111 12.8233 13.3055 12.9607C13.1 13.098 12.9398 13.2932 12.8452 13.5216C12.7505 13.7501 12.7258 14.0014 12.774 14.2439C12.8223 14.4863 12.9413 14.7091 13.1161 14.8839C13.2909 15.0587 13.5137 15.1778 13.7561 15.226C13.9986 15.2742 14.2499 15.2495 14.4784 15.1548C14.7068 15.0602 14.902 14.9 15.0393 14.6945C15.1767 14.4889 15.25 14.2472 15.25 14C15.25 13.6685 15.1183 13.3505 14.8839 13.1161C14.6495 12.8817 14.3315 12.75 14 12.75Z"/>
    </svg>
  );
}

Cash.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Cash;
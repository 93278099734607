import PropTypes from 'prop-types';

const Text = ({className = '', children} = {}) => {
  return (
    <span className={className}>{children}</span>
  );
};

Text.propTypes = {
  className: PropTypes.string
};

export default Text;

export const AccountType = {
  PUBLIC: 'public',
  SYSTEM: 'system',
  TRIP_COMPANY: 'trip-company',
  PARTNER: 'partner'
};

export const AccountStatus = {
  NEW: 'new',
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  BANNED: 'banned',
};

export const Role = {
  OWNER: 'owner',
  ADMIN: 'admin',
  MANAGER: 'manager',
  ACCOUNTANT: 'accountant',
  GUIDE: 'guide',
  DRIVER: 'driver',
  CASHIER: 'cashier',
  DISPATCHER: 'dispatcher',
  COLLECTOR: 'collector',
  MODERATOR: 'moderator'
};

export const UserAccountStatus = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
};

export const PriceType = {
  NORMAL: 'normal',
  CHILD: 'child'
};

export const PriceAppliesTo = {
  OBJECT: 'object',
  EXCURSION: 'excursion'
};

export const ExcursionType = {
  BUS: 'bus',
  WALK: 'walk',
  BOAT: 'boat'
};

export const ExcursionPickupType = {
  PLACE: 'place',
  HOME: 'home'
};

export const ModerationStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected'
};

export const ScheduleStatus = {
  ACTIVE: 'active',
  STOPPED: 'stopped',
  CANCELLED: 'cancelled'
};

export const TransportType = {
  BUS: 'bus',
  SHIP: 'ship'
};

export const FileType = {
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document',
  OTHER: 'other'
};

export const OrderStatus = {
  AWAITING_PAYMENT: 'awaiting_payment',
  PAID: 'paid',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed'
};

export const RefundStatus = {
  AWAITING_CHECK: 'awaiting_check',
  APPROVED: 'approved',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed'
};

export const PaymentType = {
  INCOME: 'income',
  OUTCOME: 'outcome',
  CANCELLED: 'cancelled',
  DISCOUNT: 'discount'
};

export const SellerType = {
  MARKETPLACE: 'marketplace',
  CASHIER: 'cashier',
  PARTNER: 'partner'
};

export const Wishes = {
  BACK_COUCH: 'back_couch',
  FIRST_SEATS: 'first_seats',
  WINDOW_SEATS: 'window_seats',
  AFTER_DRIVER: 'after_driver',
  AFTER_GUIDE: 'after_guide'
};

export const ForGroup = {
  ALL: 'all',
  ADULTS: 'adults',
  CHILDREN: 'children',
  CHILDREN_WITH_SEAT: 'children-with-seat',
  CHILDREN_WITHOUT_SEAT: 'children-without-seat'
};

export const PaymentMethod = {
  CASH: 'cash',
  CARD: 'card',
  ONLINE: 'online',
  QRCODE: 'qrcode'
};

export const DiscountType = {
  DISCOUNT: 'discount',
  PROMOCODE: 'promocode'
};

export const DynamicPriceType = {
  PERCENT: 'percent',
  ABSOLUTE: 'absolute'
};

export const BusPlaceType = {
  EMPTY: 'empty',
  SEAT: 'seat',
  TABLE: 'table',
  EXIT: 'exit',
  WC: 'wc'
};

export const Status = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended'
};

export const AppliesTo = {
  TOTAL: 'total',
  PREPAYMENT: 'prepayment',
  REMAINING: 'remaining',
};

export const APICode = {
  UNKNOWN_REQUEST: 'UNKNOWN_REQUEST',
  UNAUTHORIZED: 'UNAUTHORIZED',
  MISSING_REQUIRED_FIELDS: 'MISSING_REQUIRED_FIELDS',
  INVALID_PHONE: 'INVALID_PHONE',
  INVALID_CAPTCHA: 'INVALID_CAPTCHA',
  INVALID_TOKEN: 'INVALID_TOKEN',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_ALREADY_LOGGED_IN: 'USER_ALREADY_LOGGED_IN',
  USER_NO_PASSWORD: 'USER_NO_PASSWORD',
  USER_HAS_PASSWORD: 'USER_HAS_PASSWORD',
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  USER_IS_NOT_INVITED: 'USER_IS_NOT_INVITED',
  USER_WRONG_PROFILE: 'USER_WRONG_PROFILE',
  ALREADY_EXISTS: 'ALREADY_EXISTS',
  INVALID_ADDRESS: 'INVALID_ADDRESS',
  INVALID_SUBDIVISION: 'INVALID_SUBDIVISION',
  INVALID_PLACE: 'INVALID_PLACE',
  ERR_NETWORK: 'ERR_NETWORK',
  NOT_FOUND: 'NOT_FOUND',
  BAD_REQUEST: 'BAD_REQUEST'
};

export const CollectionType = {
  SALARY: 'salary',
  COLLECTION: 'collection'
};

export const RewardType = {
  PARTNER: 'Partner',
  CASHIER: 'Cashier',
};

export const KindOfTransport = {
  BUS: 'bus',
  MINIVAN: 'minivan',
  MINIBUS: 'minibus',
  PASSENGER_CAR: 'passenger_car',
  SHIP: 'ship'
};

export const BonusStatus = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  PAID: 'paid'
};

export const RefundRequestStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected'
};

export const RefundFor = {
  PRE_PAY: 'prepay',
  POST_PAY: 'postpay'
};

export const BillTransactionType = {
  INCOME: 'income',
  OUTCOME: 'outcome'
};

export const BillTransactionStatus = {
  SUCCEEDED: 'succeeded',
  CANCELLED: 'cancelled',
  REFUNDED: 'refunded'
};

export const BillTransactionPaymentSystem = {
  CASH: 'cash',
  TINKOFF: 'tinkoff',
  YOOKASSA: 'yookassa'
};
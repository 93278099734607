import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { OrderContext } from '../../store/OrderProvider';
import { DynamicPriceType } from '../../models/types';
import InfoDescription from '../../components/InfoDescription';
import Price, { CURRENCY } from '../../components/Price';
import RepositoryModel from '../../components/RepositoryModel';
import DateFormat from '../../components/DateFormat';
import Field from '../../components/Field';

const ConfirmOrder = ({ onNext, onClose }) => {
  const {t} = useTranslation(['common', 'order']);

  const orderInfo = useContext(OrderContext);

  const [schedule] = orderInfo.useState('schedule');

  return (
    <div className="trip-app">
      <div className="trip-app-body">
        <table>
          <tbody>
            <tr>
              <td>
                <InfoDescription description={t('order:new_order.in_check')}>
                  <Price currency={CURRENCY.RUB} value={orderInfo.getPaymentAmount()} />
                </InfoDescription>
              </td>
              <td>
                <InfoDescription description={t('order:new_order.seats')}>
                  {schedule ? orderInfo.getSeatNumbers().join(', ') : '-'}
                </InfoDescription>
              </td>
            </tr>
          </tbody>
        </table>
        <hr/>
        <Field title={t('order:confirm_order.trip')}>
          <RepositoryModel type="Excursion" uid={orderInfo.getOrderInfo().common_excursion} render={(model) => model.title} />
        </Field>
        <Field title={t('order:confirm_order.client')}>
          {orderInfo.getOrderInfo().client_phone} - {orderInfo.getOrderInfo().client_name}
        </Field>
        <Field title={t('order:confirm_order.passengers')}>
          {orderInfo.getOrderInfo().common_passengers.map((passenger) => (
            <span key={passenger.price}>{passenger.count} <RepositoryModel type="Price" uid={passenger.price} render={(model) => model.title} /></span>
          ))}
        </Field>
        <Field title={t('order:confirm_order.place')}>
          <RepositoryModel type="Place" uid={orderInfo.getOrderInfo().common_place} render={(model) => model.title} />
        </Field>
        <Field title={t('order:confirm_order.date')}>
          <DateFormat.Day date={orderInfo.getOrderInfo().common_date}/>, {orderInfo.getOrderInfo().common_time}
        </Field>
        <Field title={t('order:confirm_order.comment')}>
          {orderInfo.getOrderInfo().payment_comment || '-'}
        </Field>
        <Field title={t('order:confirm_order.additional')}>
          <>
            <div className="toggle-list">
              {orderInfo.getOrderInfo().common_wishes.map((wish) => (
                t(`order:wishes.${wish}`)
              ))}
            </div>
            {orderInfo.getOrderInfo().client_discount && (
              <div>
                <RepositoryModel type="Discount" uid={orderInfo.getOrderInfo().client_discount.id} render={(model) => (
                  model.discount ? `${model.title} - ${model.discount.type === DynamicPriceType.PERCENT ? `${model.discount.value}%` : Price.Text({ currency: CURRENCY.RUB, value: model.discount.value })}` : ''
                )} />
              </div>
            )}
          </>
        </Field>
      </div>
    </div>
  );
}

ConfirmOrder.propTypes = {
  onNext: PropTypes.func,
  onClose: PropTypes.func,
};

export default ConfirmOrder;
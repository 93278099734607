import PropTypes from 'prop-types';

const WarningHint = ({message, className = '', type = 'warning', children}) => {
  return (
    <div className={`warning-hint text-${type} ${className}`}>{message}{children}</div>
  );
};

WarningHint.propTypes = {
  message: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string
};

export default WarningHint;

import PropTypes from 'prop-types';

const Dates = ({ className = '', onClick }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={`icon ${className}`} viewBox="0 0 52 52" onClick={onClick}>
      <path d="M8,20c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S4.7,20,8,20z M26,20c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6 S22.7,20,26,20z M44,20c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S40.7,20,44,20z"/>
    </svg>
  );
}

Dates.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Dates;
import DiscountBase from './DiscountBase';
import Excursion from '../excursion/Excursion';
import Schedule from '../schedule/Schedule';
import { AppliesTo, DiscountType } from '../types';

class Discount extends DiscountBase {
  constructor({ excursion, schedule } = {}) {
    super(...arguments);
    this.excursion = this.ref(excursion, Excursion);
    this.schedule = this.ref(schedule, Schedule);
  }
  
  isApplyTo({date, user, excursion, schedule}) {
    return [DiscountType.DISCOUNT].indexOf(this.type) >= 0 &&
      [AppliesTo.TOTAL].indexOf(this.appliesTo) >= 0 &&
      (this.users.length === 0 || !user || this.users.indexOf(user.id) >= 0) &&
      (!this.excursion || !excursion || this.excursion.id === excursion.id) &&
      (!this.schedule || !schedule || this.schedule.id === schedule.id) &&
      super.isApplyTo({date});
  }
}

export default Discount;
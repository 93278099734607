import { FetchMethod, APIType, APICaller } from '../hooks/useAPI';
import AccountObject from '../models/accountObject/AccountObject';
import CashboxDetails from '../models/cashbox/CashboxDetails';
import EmployeeSchedule from '../models/employeeSchedule/EmployeeSchedule';
import OrderDetails from '../models/order/OrderDetails';
import PassengerDetails from '../models/passenger/PassengerDetails';
import ReportParams from '../models/report/ReportParams';
import Reward from '../models/reward/Reward';
import RouteDetails from '../models/route/RouteDetails';
import Schedule from '../models/schedule/Schedule';
import ScheduleExcursionDetails from '../models/schedule/ScheduleExcursionDetails';
import Collection from '../models/collection/Collection';
import Route from '../models/route/Route';
import { AccountType } from '../models/types';
import Excursion from '../models/excursion/Excursion';
import Price from '../models/price/Price';
import Order from '../models/order/Order';

const API = {
  auth: {
    signIn: new APICaller('/auth/sign-in', FetchMethod.POST, 'auth', {
      body: { phone: { required: true }, password: { required: true } },
      auth: false, type: APIType.FETCH,
      errors: [ 'MISSING_REQUIRED_FIELDS', 'INVALID_PHONE', 'WRONG_CREDENTIALS' ]
    }),
    signOut: new APICaller('/auth/sign-out', FetchMethod.DELETE, 'auth', {
      body: {},
      auth: false, type: APIType.FETCH,
      errors: []
    }),
    checkPhone: new APICaller('/auth/check-phone', FetchMethod.POST, 'auth', {
      body: { phone: { required: true } },
      auth: false, type: APIType.FETCH,
      errors: [ 'MISSING_REQUIRED_FIELDS', 'INVALID_PHONE', 'USER_ALREADY_LOGGED_IN', 'USER_NOT_FOUND', 'USER_NO_PASSWORD', 'USER_HAS_PASSWORD' ]
    }),
    setPassword: new APICaller('/auth/set-password', FetchMethod.POST, 'auth', {
      body: { token: { required: true }, password: { required: true } },
      auth: false, type: APIType.FETCH,
      errors: [ 'MISSING_REQUIRED_FIELDS', 'INVALID_TOKEN', 'USER_NOT_FOUND' ]
    }),
  },
  user: {
    whoami: new APICaller('/user', FetchMethod.GET, 'users', {
      body: {},
      auth: false, type: APIType.FETCH,
      errors: []
    }),
    sendCode: new APICaller('/users/send-code', FetchMethod.POST, 'users', {
      body: {
        phone: { required: true },
        captcha: { required: true },
        lang: { required: false }
      },
      auth: false, type: APIType.FETCH,
      errors: [ 'INVALID_PHONE', 'INVALID_CAPTCHA', 'MISSING_REQUIRED_FIELDS' ]
    }),
    validateCode: new APICaller('/users/validate-code', FetchMethod.POST, 'users', {
      body: {
        phone: { required: true },
        code: { required: true }
      },
      auth: false, type: APIType.FETCH,
      errors: [ 'INVALID_PHONE', 'MISSING_REQUIRED_FIELDS' ]
    }),
    updateUser: new APICaller('/user', FetchMethod.PATCH, 'users', {
      body: {
        avatar: { required: false },
        nickname: { required: false },
        firstName: { required: false },
        lastName: { required: false },
        middleName: { required: false },
        birthday: { required: false }
      },
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    changePhone: new APICaller('/users/change-phone', FetchMethod.POST, 'users', {
      body: {
        oldToken: { required: true },
        newToken: { required: true }
      },
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    changePassword: new APICaller('/user/change-password', FetchMethod.POST, 'users', {
      body: {
        currentPassword: { required: true },
        newPassword: { required: true }
      },
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    uploadAvatar: new APICaller('/users/upload-avatar', FetchMethod.POST, 'users', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    user: new APICaller('/accounts/:accountId/users/:id', FetchMethod.GET, 'users', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    users: new APICaller('/accounts/:accountId/users', FetchMethod.POST, 'users', {
      body: new ReportParams(),
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    tickets: new APICaller('/users/tickets', FetchMethod.GET, 'users', {
      body: new ReportParams(),
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    autocompleteList: new APICaller('/accounts/:accountId/users/autocomplete-list', FetchMethod.GET, 'users', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
  },
  account: {
    users: new APICaller('/accounts/:accountId/users', FetchMethod.POST, 'accounts', {
      body: {
        filters: { required: false, fields: [
          'phone',
          'roles',
          'status', // `active`, `suspended`
          'language',
          'profile.lastName',
          'profile.firstName',
          'profile.middleName',
          'profile.fullName',
        ]},
        sort: { required: false, fields: [
          'phone',
          'account.status', // `active`, `suspended`
          'language',
          'profile.lastName',
          'profile.firstName',
          'profile.middleName',
          'profile.fullName',
        ] },
        page: { required: false },
        pageSize: { required: false }
      },
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    invitations: new APICaller('/accounts/:accountId/users/invitations', FetchMethod.POST, 'accounts', {
      body: {
        filters: { required: false, fields: [
          'phone',
          'language',
          'profile.lastName',
          'profile.firstName',
          'profile.middleName',
          'profile.fullName',
        ]},
        sort: { required: false, fields: [
          'phone',
          'expiresAt',
          'language',
          'profile.lastName',
          'profile.firstName',
          'profile.middleName',
          'profile.fullName',
        ] },
        page: { required: false },
        pageSize: { required: false }
      },
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    invite: new APICaller('/accounts/:accountId/users/invite', FetchMethod.POST, 'accounts', {
      body: {
        phone: { required: true },
        roles: { required: true },
        firstName: { required: true },
        lastName: { required: true },
        middleName: { required: false },
        birthday: { required: true },
      },
      auth: true, type: APIType.FETCH,
      errors: [ 'MISSING_REQUIRED_FIELDS', 'INVALID_PHONE', 'USER_ALREADY_ADDED', 'USER_ALREADY_INVITED', 'USER_WRONG_PROFILE' ]
    }),
    showInvitation: new APICaller('/users/invitation/:code', FetchMethod.GET, 'accounts', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: [ 'INVALID_CODE' ]
    }),
    resendInvitation: new APICaller('/accounts/:accountId/users/resend-invitation', FetchMethod.POST, 'accounts', {
      body: {
        phone: { required: true },
      },
      auth: true, type: APIType.FETCH,
      errors: [ 'MISSING_REQUIRED_FIELDS', 'INVALID_PHONE', 'USER_ALREADY_ADDED', 'USER_IS_NOT_INVITED', 'USER_WRONG_PROFILE' ]
    }),
    deleteInvitation: new APICaller('/accounts/:accountId/users/invitation', FetchMethod.DELETE, 'accounts', {
      body: {
        phone: { required: true },
      },
      auth: true, type: APIType.FETCH,
      errors: [ 'MISSING_REQUIRED_FIELDS', 'INVALID_PHONE', 'USER_ALREADY_ADDED', 'USER_IS_NOT_INVITED', 'USER_WRONG_PROFILE' ]
    }),
    updateUser: new APICaller('/accounts/:accountId/users/:userId', FetchMethod.PATCH, 'accounts', {
      body: {
        status: { required: true },
        roles: { required: true }
      },
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    permissions: new APICaller('/accounts/:accountId/users/acl', FetchMethod.GET, 'accounts', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    })
  },
  point: {
    points: new APICaller('/accounts/:accountId/points', FetchMethod.GET, 'points', {
      body: {
        title: { required: false }
      },
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    autocompleteList: new APICaller('/accounts/:accountId//points/autocomplete-list', FetchMethod.GET, 'points', {
      body: new ReportParams(),
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    report: new APICaller('/accounts/:accountId/points/report', FetchMethod.POST, 'points', {
      body: new ReportParams(),
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    point: new APICaller('/accounts/:accountId/points/:id', FetchMethod.GET, 'points', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
  },
  excursion: {
    autocomplete: new APICaller('/accounts/:accountId/excursions/autocomplete', FetchMethod.GET, 'excursions', {
      body: {
        query: { required: false }
      },
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    autocompleteList: new APICaller('/accounts/:accountId/excursions/autocomplete-list', FetchMethod.GET, 'excursions', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    excursions: new APICaller('/accounts/:accountId/excursions/list/:date', FetchMethod.GET, 'excursions', { // YYYY-MM-DD
      body: {
        date: { required: true },
        timezone: { required: true }, // 'Europe/Moscow'
        subdivision: { required: true }
      },
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: Schedule
    }),
    excursion: new APICaller('/accounts/:accountId/excursions/:id', FetchMethod.GET, 'excursions', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: Excursion
    }),
    report: new APICaller('/accounts/:accountId/excursions/report', FetchMethod.POST, 'excursions', {
      body: new ReportParams(),
      auth: true, type: APIType.FETCH,
      errors: []
    }),
  },
  geo: {
    getCountries: new APICaller('/misc/geos/countries', FetchMethod.GET, 'geos', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
  },
  subdivision: {
    subdivisions: new APICaller('/accounts/:accountId/subdivisions', FetchMethod.GET, 'subdivisions', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    subdivision: new APICaller('/accounts/:accountId/subdivisions/:id', FetchMethod.GET, 'subdivisions', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
  },
  price: {
    prices: new APICaller('/misc/prices', FetchMethod.GET, 'prices', {
      body: {
        tab: { required: true }
      },
      auth: true, type: APIType.FETCH,
      // accountType: AccountType.SYSTEM,
      errors: [],
      clazz: Price
    }),
    price: new APICaller('/admin/prices/:id', FetchMethod.GET, 'prices', {
      body: {},
      auth: true, type: APIType.FETCH,
      accountType: AccountType.SYSTEM,
      errors: []
    }),
  },
  place: {
    places: new APICaller('/accounts/:accountId/subdivisions/:id/places', FetchMethod.GET, 'places', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    place: new APICaller('/accounts/:accountId/places/:id', FetchMethod.GET, 'places', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
  },
  discount: {
    discounts: new APICaller('/accounts/:accountId/discounts/report', FetchMethod.POST, 'discounts', {
      body: new ReportParams(),
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    discount: new APICaller('/accounts/:accountId/discounts/:id', FetchMethod.GET, 'discounts', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
  },
  object: {
    accountObjects: new APICaller('/accounts/:accountId/objects/:id', FetchMethod.GET, 'objects', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: AccountObject
    }),
  },
  schedule: {
    schedule: new APICaller('/accounts/:accountId/schedules/:id', FetchMethod.GET, 'schedules', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: Schedule
    }),
    dates: new APICaller('/accounts/:accountId/excursions/:id/schedules/dates', FetchMethod.GET, 'schedules', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    times: new APICaller('/accounts/:accountId/excursions/:id/schedules/:date/times', FetchMethod.GET, 'schedules', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    report: new APICaller('/accounts/:accountId/schedules/report', FetchMethod.POST, 'schedules', {
      body: new ReportParams(),
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: ScheduleExcursionDetails
    }),
    datesSeats: new APICaller('/accounts/:accountId/excursions/:id/schedules/dates-seats', FetchMethod.GET, 'schedules', {
      body: {
        subdivision: { required: true }
      },
      auth: true, type: APIType.FETCH,
      errors: []
    }),
  },
  order: {
    createOrder: new APICaller('/accounts/:accountId/orders', FetchMethod.POST, 'orders', {
      body: {
        user: { required: false },
        userInfo: { required: false },
        excursion: { required: true },
        subdivision: { required: true },
        schedule: { required: true },
        passengers: { required: true },
        sellerType: { required: false },
        pointId: { required: false },
        paymentMethod: { required: true },
        contactPhones: { required: false },
        wishes: { required: false },
        place: { required: false },
        address: { required: false },
        passports: { required: false },
        comment: { required: false },
        discountId: { required: false },
      },
      auth: true, type: APIType.FETCH,
      errors: [ 'MISSING_REQUIRED_FIELDS', 'ALREADY_EXISTS', 'INVALID_ADDRESS', 'INVALID_SUBDIVISION', 'INVALID_PLACE' ]
    }),
    orders: new APICaller('/accounts/:accountId/orders/report', FetchMethod.POST, 'orders', {
      body: new ReportParams({
        filters: ['number', 'date', 'place', 'point', 'subdivision', 'paymentDetails.method', 'status', 'schedule', 'excursion', 'schedule.status', 'schedule.date', 'transports._id'],
        sort: ['number', 'schedule.date', 'transports.number', 'paymentDetails.paidAmount', 'paymentDetails.method', 'status']
      }),
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    autocomplete: new APICaller('/accounts/:accountId/orders/clientAutocomplete', FetchMethod.GET, 'orders', {
      body: {
        query: { required: false }
      },
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    order: new APICaller('/accounts/:accountId/orders/:id', FetchMethod.GET, 'orders', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: Order
    }),
    cashPayment: new APICaller('/accounts/:accountId/orders/:id/cash-payment', FetchMethod.POST, 'orders', {
      body: {
        amount: { required: true }, // сумма платежа
        comment: { required: false } // комментарий к платежу
      },
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    nfcPayment: new APICaller('/accounts/:accountId/orders/:id/nfc-payment', FetchMethod.POST, 'orders', {
      body: {
        terminalKey: { required: true }, // ключ терминала с которого произведена оплата
        rrn: { required: true }, // ID платежа (rnn из колбека)
        transactionDate: { required: true }, // дата и время платежа из колбека!
        amount: { required: true }, // сумма платежа
        comment: { required: false } // комментарий к платежу - **необязательный параметр**
      },
      auth: true, type: APIType.FETCH,
      errors: [ 'PAYMENT_ALREADY_EXISTS', 'ORDER_ALREADY_PAID', 'INVALID_AMOUNT', 'OPERATION_NOT_FOUND' ]
    }),
  },
  cashier: {
    orders: new APICaller('/accounts/:accountId/roles/cashiers/orders', FetchMethod.GET, 'cashiers', {
      body: {
        client: { required: false },
        date: { required: false }
      },
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: OrderDetails
    }),
    passengers: new APICaller('/accounts/:accountId/roles/cashiers/passengers', FetchMethod.GET, 'cashiers', {
      body: {
        date: { required: false }
      },
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: PassengerDetails
    }),
    setPassengers: new APICaller('/accounts/:accountId/roles/cashiers/passengers', FetchMethod.POST, 'cashiers', {
      body: {
        order: { required: true }, //ID
        seat: { required: true }, //boolean
        place: { required: true }, //ID
      },
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: PassengerDetails
    }),
    schedules: new APICaller('/accounts/:accountId/roles/cashiers/schedules', FetchMethod.GET, 'cashiers', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: EmployeeSchedule
    }),
    cashbox: new APICaller('/accounts/:accountId/roles/cashiers/cashbox', FetchMethod.GET, 'cashiers', {
      body: {
        fromDate: { required: true }, //YYYY-MM-DD
        toDate: { required: true }, //YYYY-MM-DD
      },
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: CashboxDetails
    }),
    salaries: new APICaller('/accounts/:accountId/roles/cashiers/salaries', FetchMethod.GET, 'cashiers', {
      body: {
        fromDate: { required: true }, //YYYY-MM-DD
        toDate: { required: true }, //YYYY-MM-DD
      },
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: Reward
    }),
    withdrawSalary: new APICaller('/accounts/:accountId/roles/cashiers/withdraw-salary', FetchMethod.POST, 'cashiers', {
      body: {
        amount: { required: true }
      },
      auth: true, type: APIType.FETCH,
      errors: [],
    }),
  },
  guide: {
    schedules: new APICaller('/accounts/:accountId/roles/guides/schedules', FetchMethod.GET, 'guides', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: RouteDetails
    }),
  },
  collectors: {
    collections: new APICaller('/accounts/:accountId/roles/collectors/collections', FetchMethod.GET, 'collectors', {
      body: {
        fromDate: { required: true }, //YYYY-MM-DD
        toDate: { required: true }, //YYYY-MM-DD
      },
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: Collection
    }),
    createCollection: new APICaller('/accounts/:accountId/roles/collectors/collections', FetchMethod.POST, 'collectors', {
      body: {
        user: { required: true },
        amount: { required: true },
      },
      auth: true, type: APIType.FETCH,
      errors: [],
      clazz: Collection
    }),
  },
  route: {
    routes: new APICaller('/accounts/:accountId/routes/report', FetchMethod.POST, 'routes', {
      body: new ReportParams({
        filters: ['title', 'status', 'subdivisions.subdivision'],
        sort: ['title', 'status']
      }),
      auth: true, type: APIType.FETCH,
      errors: []
    }),
    route: new APICaller('/accounts/:accountId/routes/:id', FetchMethod.GET, 'routes', {
      body: {},
      auth: true, type: APIType.FETCH,
      errors: [],
      Route
    }),
  },
  bonus: {
    bonuses: new APICaller('/accounts/:accountId/bonuses/report', FetchMethod.POST, 'bonuses', {
      body: new ReportParams({
        filters: ['title'],
        sort: ['title']
      }),
      auth: true, type: APIType.FETCH,
      errors: []
    }),
  },
};

export const getAPI = (url) => {
  for (const moduleName in API) {
    for (const methodName in API[moduleName]) {
      if (API[moduleName][methodName].url === url) {
        return [moduleName, methodName, API[moduleName][methodName]];
      }
    }
  }
  return [null, null, null];
};

export default API;
import ClassHelper from '../ClassHelper';
import Discount from './Discount';

class DiscountDetails extends ClassHelper {
  constructor({ label, meta } = {}) {
    super(...arguments);
    this.label = label;
    this.meta = this.ref(meta, Discount);
  }
}

export default DiscountDetails;
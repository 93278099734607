import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPE } from './Button';
import ShowIcon from './icons/Show';
import HideIcon from './icons/Hide';
import Field from './Field';
import Input from './Input';

export const UPLOAD_STATUS = {
  INITIAL: 'initial',
  UPLOADING: 'uploading',
  SUCCESS: 'success',
  FAIL: 'fail'
};

export const ACCEPT_FILE_FORMAT = {
  IMAGE: 'image/png, image/jpeg',
  PDF: 'application/pdf',
  ALL: '*'
};

const InputForm = ({ label, ...props}) => {
  return (
    <Field title={label}>
      <Input {...props}/>
    </Field>
  );
};

InputForm.propTypes = {
  label: PropTypes.string,
  ...Input.propTypes
};

export default InputForm;

import React from 'react';
import PropTypes from 'prop-types';

class Stepper2 extends React.Component {
  constructor(props) {
    super(props);
    this.onSelect = typeof props.onSelect === 'function' ? props.onSelect : () => {};
    this.onComplete = typeof props.onComplete === 'function' ? props.onComplete : () => {};
    this.onError = typeof props.onError === 'function' ? props.onError : () => {};

    this.state = {
      selected: 0,
      inprocess: 0,
      completed: [],
      errors: []
    };

    if (this.props.steps.length > 0 && !this.props.steps.find((step) => step.selected)) {
      this.props.steps[0].selected = true;
    }
  }

  componentDidMount() {
    this.props.steps.forEach((step, i) => {
      if (step.id === undefined) {
        step.id = `step_${i + 1}`;
      }

      if (step.selected) {
        this.setState({ selected: i });
      }

      if (step.comeplted) {
        this.setState({ inprocess: Math.min(Math.max(i + 1, this.state.inprocess), this.props.steps.length) });
      }

      step.addListeners('onSelect', (selected) => {
        if (selected) {
          this.setState({ selected: i });
          this.onSelect(i);
        }
      });

      step.addListeners('onComplete', (completed) => {
        let completedList = this.state.completed;
        completedList[i] = !!completed;
        this.setState({ completed: completedList })
        if (completed) {
          this.setState({ inprocess: Math.min(Math.max(i + 1, this.state.inprocess), this.props.steps.length) });
        } else {
          this.setState({ inprocess: i });
        }

        this.setState({ completed: [...this.state.completed] });
      });

      step.addListeners('onError', (err) => {
        let errors = this.state.errors;
        errors[i] = err;
        this.setState({ errors: [...errors] });
      });
    });

    window.addEventListener('hashchange', () => this.handleHashChange(), false);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', () => this.handleHashChange(), false);
  }

  handleHashChange() {
    let id = window.location.hash.slice(1);
    let stepIdx = this.props.steps.findIndex((step) => step.id === id);
    this.goToStep(stepIdx);
  }

  goToStep(idx) {
    let step = this.getStep(idx);

    if (step && step.completed) {
      this.setState({ selected: idx });
    }
  }

  nextStep() {
    this.props.steps[this.state.selected].complete();

    let stepIdx = Math.min(this.state.selected + 1, Math.max(this.props.steps.length - 1, 0));

    let step = this.getStep(stepIdx);
    if (step) {
      window.location.hash = step.id;
    }

    this.setState({ selected: stepIdx });
  }

  previousStep() {
    let stepIdx = Math.max(this.state.selected - 1, 0);

    let step = this.getStep(stepIdx);
    if (step) {
      window.location.hash = step.id;
    }

    this.setState({ selected: stepIdx });
  }

  getStep(idx) {
    return this.props.steps.length > idx && idx >= 0 ? this.props.steps[idx] : null;
  }

  getCurrentStep() {
    return this.props.steps.length > 0 ? this.props.steps[this.state.selected] : null;
  }

  getCurrentStepIdx() {
    return this.state.selected;
  }

  isCurrent(idx) {
    return this.state.selected === idx;
  }

  getTitle() {
    const step = this.getCurrentStep();
    return step ? step.title : '';
  }

  getStatus(idx) {
    return this.props.steps[idx].status;
  }

  completeStep(idx) {
    this.props.steps[idx].complete();
  }

  handleSelectItem(idx) {
    const step = this.getStep(idx);
    if (step && (this.props.steps[idx].completed)) {
      this.setState({ selected: idx });
      step.select();
    }
  }

  render() {
    return (
      <>
        <div className="stepper2-current-name">{this.getTitle()}</div>
        <div className="stepper2-wrapper">
          <div className="first-counter"></div>
          {this.props.steps.map((step, i) => (
            <Stepper2.Item key={i} onClick={() => {
              this.handleSelectItem(i);
              // step.select();
            }} completed={this.state.completed[i]} selected={this.state.selected === i}>
              <Stepper2.Counter/>
            </Stepper2.Item>
          ))}
        </div>
      </>
    );
  }
}

Stepper2.propTypes = {
  steps: PropTypes.array,
  onSelect: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func
};

const Item = ({ onClick, completed = false, selected = false, children }) => {
  return (
    <div onClick={(onClick)} className={('stepper2-item' + (completed ? ' completed' : '') + (selected ? ' selected' : ''))}>
      {children}
    </div>
  );
};


Item.propTypes = {
  comeplted: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func
};

Stepper2.Item = Item;

const Counter = ({ children }) => {
  return (
    <div className="step-counter">
      {children}
    </div>
  );
};

Stepper2.Counter = Counter;

const Name = ({ children }) => {
  return (
    <div className="step-name">
      {children}
    </div>
  );
};

Stepper2.Name = Name;

class Step
{
  constructor({ id, title = '', selected = false, completed = false, onSelect, onComplete, onReset, onError, validate }) {
    this.id = id;
    this.rid = Math.random(10000);
    this.title = title;
    this.completed = completed;
    this.error = null;
    this.onSelect = typeof onSelect === 'function' ? onSelect : () => {};
    this.onComplete = typeof onComplete === 'function' ? onComplete : () => {};
    this.onReset = typeof onReset === 'function' ? onReset : () => {};
    this.onError = typeof onError === 'function' ? onError : () => {};
    this.validate = typeof validate === 'function' ? validate : () => {};
    this.listeners = {
      onSelect: [],
      onComplete: [],
      onError: []
    };
  }

  complete() {
    this.completed = true;
    this._do('onComplete', true);
    this.onComplete();
  }

  reset() {
    this.completed = false;
    this._do('onComplete', false);
  }

  select() {
    this._do('onSelect', true);
    this.onSelect(true);
  }

  unselect() {
    this._do('onSelect', false);
    this.onSelect(false);
  }

  error(err) {
    this.error = err;
    this._do('onError', err);
    this.onError(err);
  }

  addListeners(eventName, action) {
    if (this.listeners[eventName]) {
      this.listeners[eventName].push(action);
    }
  }

  removeListeners(eventName, action) {
    if (this.listeners[eventName]) {
      let index = this.listeners[eventName].indexOf(action);
      if (index !== -1) {
        this.listeners[eventName].splice(index, 1);
      }
    }
  }

  _do(eventName, ...args) {
    if (this.listeners[eventName]) {
      this.listeners[eventName].forEach((action) => {
        try {
          action(...args);
        } catch(e) {
          console.error(e);
        }
      });
    }
  }
};

Step.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  completed: PropTypes.bool,
  onSelect: PropTypes.func,
  onComplete: PropTypes.func,
  onReset: PropTypes.func,
  onError: PropTypes.func,
  validate: PropTypes.func
};

export { Step };

export default Stepper2;
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button, { BUTTON_SIZE, BUTTON_TYPE } from '../../components/Button';
import Row from '../../components/Row';
import CashIcon from '../../components/icons/Cash';
import DeleteIcon from '../../components/icons/Delete';
import FlashIcon from '../../components/icons/Flash';
import Text from '../../components/Text';
import Modal, { MODAL_TYPE } from '../../components/Modal';
import RemoveFromSchedule from './remove_from_schedule';
import { useCallback, useState } from 'react';

const ChangeOrderState = ({ order, onClose, onOpen, onHide }) => {
  const {t} = useTranslation(['common', 'order']);

  const [showRemoveFromSchedule, setShowRemoveFromSchedule] = useState(false);

  const handleCloseRemoveFromSchedule = useCallback(() => {
    setShowRemoveFromSchedule(false);
    onOpen(order);
  }, []);

  const handleOpenRemoveFromSchedule = useCallback(() => {
    setShowRemoveFromSchedule(true);
    // onHide();
  }, []);


  return (
    <div className="trip-app">
      <div className="trip-app-body">
        <Row>
          <Button variant={BUTTON_TYPE.SECONDARY} size={BUTTON_SIZE.AUTO}><CashIcon /><Text>{t('order:change_status.button.paid')}</Text></Button>
        </Row>
        <Row>
          <Button variant={BUTTON_TYPE.SECONDARY} size={BUTTON_SIZE.AUTO} onClick={handleOpenRemoveFromSchedule}><DeleteIcon /><Text>{t('order:change_status.button.cancel')}</Text></Button>
        </Row>
        <Row>
          <Button variant={BUTTON_TYPE.SECONDARY} size={BUTTON_SIZE.AUTO}><FlashIcon /><Text>{t('order:change_status.button.schedule')}</Text></Button>
        </Row>


        <Modal show={showRemoveFromSchedule} type={MODAL_TYPE.FULLSCREAN} onHide={handleCloseRemoveFromSchedule}>
          <Modal.Header closeButton>
            {t('order:edit_order.title', { order: order && order.number })}
          </Modal.Header>
          <Modal.Body>
            {order && showRemoveFromSchedule && ( /* re-create body*/
              <RemoveFromSchedule order={order} onClose={handleCloseRemoveFromSchedule} />
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

ChangeOrderState.propTypes = {
  order: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onHide: PropTypes.func
};

export default ChangeOrderState;
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPE } from './Button';
import ShowIcon from './icons/Show';
import HideIcon from './icons/Hide';

export const UPLOAD_STATUS = {
  INITIAL: 'initial',
  UPLOADING: 'uploading',
  SUCCESS: 'success',
  FAIL: 'fail'
};

export const ACCEPT_FILE_FORMAT = {
  IMAGE: 'image/png, image/jpeg',
  PDF: 'application/pdf',
  ALL: '*'
};

const Input = ({ label, type = 'text', value = '', id, placeholder, name, className = '', onChange, readOnly = false, onEnter, icons, preIcons, note }) => {
  const [inputType, setInputType] = useState(type);

  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className={`input-field ${className}`}>
      {label && (
        <label className="field-label">{label}</label>
      )}

      {note && (
        <span className='input-note'>{note}</span>
      )}

      <div className={(type === 'password' || (icons && (!(icons instanceof Array) || icons.length > 0)) || (preIcons && (!(preIcons instanceof Array) || preIcons.length > 0))) ? 'input-group' : 'input'}>
        {(preIcons && (!(preIcons instanceof Array) || preIcons.length > 0)) && (
          <>
            {(preIcons instanceof Array ? preIcons : [preIcons]).map((icon, key) => (
              <span key={key} className="input-group-text">
                {icon}
              </span>
            ))}
          </>
        )}

        <input className="text form-control" type={inputType} value={inputValue} id={id} name={name} placeholder={placeholder} readOnly={readOnly}
          onChange={(e) => {
            setInputValue(e.target.value);
            (typeof onChange === 'function') && onChange(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && typeof onEnter === 'function') {
              e && e.preventDefault();
              onEnter(e.target.value.trim());
            }
          }}
        />

        {(type === 'password') && (
          <span className="input-group-text" onClick={() => setInputType(inputType === 'text' ? 'password' : 'text')}>
            {(inputType === 'password') ? <HideIcon /> : <ShowIcon /> }
          </span>
        )}

        {(icons && (!(icons instanceof Array) || icons.length > 0)) && (
          <>
            {(icons instanceof Array ? icons : [icons]).map((icon, key) => (
              <span key={key} className="input-group-text">
                {icon}
              </span>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  note: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  icons: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  preIcons: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

const File = ({ className = '', label, onChange, onStatus, onClick, disabled, children, accept = ACCEPT_FILE_FORMAT.ALL }) => {
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState(UPLOAD_STATUS.INITIAL);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setStatus(UPLOAD_STATUS.INITIAL);
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    typeof onStatus === 'function' && onStatus(status);
  }, [status]);

  useEffect(() => {
    typeof onChange === 'function' && onChange(file);
  }, [file]);

  return (
    <>
      <Button variant={BUTTON_TYPE.PRIMARY} className={className} onClick={onClick} disabled={disabled}>
        <label className="input-file">
          <input type="file" onChange={handleFileChange} accept={accept instanceof Array ? accept.toString() : accept} capture />
          <span>{label}</span>
        </label>
      </Button>
    </>
  );
};

File.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  position: PropTypes.string,
  onStatus: PropTypes.func,
  onChange: PropTypes.func
};

Input.File = File;

export default Input;

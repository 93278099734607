import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Select = ({ className = '', options, empty, value, disabled, onSelect }) => {
  const [val, setVal] = useState(value);

  const updateValue = ({ target }) => {
    setVal(target.value);
    typeof onSelect === 'function' && onSelect(target.value, target);
  };

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <>
      <select className={'select-box form-control ' + className} value={val} onChange={updateValue} disabled={disabled}>
        {empty && <option value={empty.value}>{empty.text}</option>}
        {options.map((option, i) => (
          <option value={option.value} key={i}>
            {option.text}
          </option>
        ))}
      </select>
    </>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  empty: PropTypes.object,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Select;
import { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { UserContext } from '../../store/UserProvider';
import { OrderContext } from '../../store/OrderProvider';
import { RepositoryContext } from '../../store/RepositoryProvider';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Price, { CURRENCY } from '../../components/Price';
import API from '../../server/api';
import Alert, { ALERT_TYPE } from '../../components/Alert';
import { attachAndroidEvent, callToDevice, payToPhone, removeAndroidEvent } from '../../utils/events';
import { PaymentMethod } from '../../models/types';
import Field from '../../components/Field';
import log from '../../utils/logger';

const Payment = ({type, onSuccess, onCancel, onError}) => {
  const {t} = useTranslation(['common', 'order']);

  const userInfo = useContext(UserContext);
  const orderInfo = useContext(OrderContext);
  const repository = useContext(RepositoryContext);

  const [oddMoney, setOddMoney] = useState(0);
  const [money, setMoney] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState({});
  const [errors, setErrors] = useState([]);

  const handlePayment = useCallback(async () => {
    if (type === PaymentMethod.CASH) {
      if (money < orderInfo.getPaymentAmount()) {
        setErrors({ code: 'payment_error', type: ALERT_TYPE.ERROR, title: t('order:error.not_enough_money'), message: t('order:error.not_enough_money') });
      } else {
        repository.action(API.order.cashPayment, {
          amount: orderInfo.getPaymentAmount() ,
          id: userInfo.getOrderSession().order_id,
          comment: userInfo.getOrderSession().comment
        }).then((payment) => {
          userInfo.setOrderSession({});
          onSuccess && onSuccess();
        }).catch((error) => {
          log.error(error);
          onError && onError();
        });
      }
    } else if (type === PaymentMethod.CARD) {
      payToPhone(orderInfo.getPaymentAmount(), PaymentMethod.CARD);
    } else if (type === PaymentMethod.QRCODE) {
      payToPhone(orderInfo.getPaymentAmount(), PaymentMethod.QRCODE);
    }
  }, [money]);

  const handlePaymentClose = useCallback(async () => {
    onCancel && onCancel();
  }, []);

  const handleClientCash = useCallback((value) => {
    let clientCash = Number(value);
    if (clientCash) {
      setOddMoney(Math.max(clientCash - orderInfo.getPaymentAmount(), 0));
    }

    setMoney(clientCash);
  }, []);

  const handleClientNFC = useCallback(() => {
    callToDevice('enableNFC', true);
  }, [money]);

  useEffect(() => {
    attachAndroidEvent('payment', async (e) => {
      const { type, id, terminalKey, error } = e.detail;
      if (error) {
        setPaymentStatus({ error });
        setErrors({ code: 'payment_error', type: ALERT_TYPE.ERROR, title: error, message: error });
      } else {
        if (type === PaymentMethod.CARD) {
          setPaymentStatus({ type, rrn: id });
          repository.action(API.order.nfcPayment, {
            amount: money,
            orderId: userInfo.getOrderSession().order_id,
            comment: userInfo.getOrderSession().comment,
            rrn: id,
            terminalKey: terminalKey,
            transactionDate: new Date().toISOString()
          }).then(() => {
            // onSuccess && onSuccess();
            setPaymentStatus({ type, status: t('order:payment.paid') });
          }).catch((error) => {
            setPaymentStatus({ type, status: t('order:payment.no_paid') });
            log.error(error);
          });
        } else if (type === PaymentMethod.QRCODE) {
          setPaymentStatus({ type, paymentId: id });
          repository.action(API.order.nfcPayment, {
            amount: money,
            orderId: userInfo.getOrderSession().order_id,
            comment: userInfo.getOrderSession().comment,
            rrn: id,
            terminalKey: terminalKey,
            transactionDate: new Date().toISOString()
          }).then((payment) => {
            onSuccess && onSuccess();
          }).catch((error) => {
            log.error(error);
          });
        }
      }
    });

    return () => {
      removeAndroidEvent('payment');
    };
  }, []);

  const handleCloseError = () => {
    setErrors([]);
  };


  return (
    <div>
      {(errors.length > 0) && errors.map((error) => (
        <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.type)}>{error.message}</Alert>
      ))}
      {(type === PaymentMethod.CARD) && (
        <Field title={t('order:payment.total_amount')}>
          <Price currency={CURRENCY.RUB} value={orderInfo.getPaymentAmount()} />
          <Button onClick={handlePayment}>{t('order:payment_booking.scan')}</Button>
        </Field>
      )}
      {(type === PaymentMethod.CASH) && (
        <>
          <Field title={t('order:payment.total_amount')}>
            <Price currency={CURRENCY.RUB} value={orderInfo.getPaymentAmount()} />
          </Field>
          <Field title={t('order:payment.client_cash')}>
            <Price.Input onInput={handleClientCash} />
          </Field>
          <Field title={t('order:payment.odd_money')}>
            <Price currency={CURRENCY.RUB} value={oddMoney} />
          </Field>
        </>
      )}

      {paymentStatus && (
        <Field>
          {paymentStatus.status}
        </Field>
      )}

      <Button.Panel>
        {(type === PaymentMethod.CASH) && (
          <Button position={BUTTON_POSITION.LEFT} variant={BUTTON_TYPE.PRIMARY} onClick={handlePayment}>{t('common:button.paid')}</Button>
        )}
        {(type === PaymentMethod.CARD) && (
          <Button onClick={handlePayment}>{t('order:payment_booking.nfc')}</Button>
        )}
        {(type === PaymentMethod.QRCODE) && (
          <Button onClick={handlePayment}>{t('order:payment_booking.scan')}</Button>
        )}
        <Button position={BUTTON_POSITION.RIGHT} variant={BUTTON_TYPE.SECONDARY} onClick={handlePaymentClose}>{t('common:button.cancel')}</Button>
      </Button.Panel>
    </div>
  );
};

Payment.propTypes = {
  type: PropTypes.string,
  onSuccess: PropTypes.func,
  onCance: PropTypes.func,
  onError: PropTypes.func,
};

export default Payment;
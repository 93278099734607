import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DropDownIcon from './icons/DropDown';
import DropUpIcon from './icons/DropUp';

const InputAutocomplete = ({ value = '', placeholder, list, className = '', onSelect, onChange, onInput }) => {
  const [suggestions, setSugesstions] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [text, setText] = useState(value);
  const showSuggestionRef = useRef(false);

  const handleInput = (e) => {
    let reg = new RegExp(e.target.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
    let founded = list.filter((item) => reg.test(item));

    setText(e.target.value);
    setSugesstions(founded);
    _setShowSuggestion(founded.length > 0);

    typeof onInput === 'function' && onInput(e.target.value);
  };

  const _setShowSuggestion = (b) => {
    showSuggestionRef.current = b;
    setShowSuggestion(b);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setText(value);

    typeof onChange === 'function' && onChange(value);

    let founded = list.filter((item) => typeof item === 'string' ? (item.toLowerCase() === value.toLowerCase()) : (item === value));
    if (founded.length > 0) {
      typeof onSelect === 'function' && onSelect(founded[0]);
    }
  };

  const handleSelect = (value) => {
    setText(value);
    _setShowSuggestion(false);

    typeof onSelect === 'function' && onSelect(value);
    typeof onChange === 'function' && onChange(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e && e.preventDefault();
      handleChange(e);
    }
  };

  const closeSuggestion = (e) => {
    if (showSuggestionRef.current && (!e || !(
      e.target.closest('.sugesstion-auto') ||
      e.target.classList.contains('sugesstion-auto') ||
      e.target.closest('.autocomplete-drop-icon') ||
      e.target.classList.contains('autocomplete-drop-icon')
    ))) {
      _setShowSuggestion(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeSuggestion);

    return () => {
      document.removeEventListener('click', closeSuggestion);
    };
  }, []);

  useEffect(() => {
    setSugesstions(list);
  }, [list]);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <div className={`sugesstion-auto ${className}`}>
      <div className={`input-group ${className}`}>
        <input className="form-control" placeholder={placeholder} type="search" value={text} onBlur={handleChange} onInput={handleInput} onKeyDown={handleKeyDown} />
        <span className="input-group-text" onClick={() => {
          _setShowSuggestion(!showSuggestionRef.current);
        }}>
          {showSuggestionRef.current ? <DropUpIcon className="autocomplete-drop-icon" /> : <DropDownIcon className="autocomplete-drop-icon" />}
        </span>
      </div>
      {showSuggestionRef.current && (
        <div className="suggestions">
          {suggestions.map((item, idx) => (
            <div key={idx} className="suggestion-item" onClick={() => handleSelect(item)}>
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

InputAutocomplete.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  list: PropTypes.array,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  onInput: PropTypes.func
};

export default InputAutocomplete;
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RepositoryContext } from '../../store/RepositoryProvider';
import { UserContext } from '../../store/UserProvider';
import NavBar from '../../components/NavBar';
import ReportFilter, { SHOW_MODE } from '../../components/ReportFilter';
import API from '../../server/api';
import Table, { ALIGN_TABLE } from '../../components/Table';
import InfoDescription from '../../components/InfoDescription';
import Badge, { BADGE_TYPE } from '../../components/Badge';
import DateFormat from '../../components/DateFormat';
import Phone from '../../components/Phone';
import log from '../../utils/logger';
import Modal from '../../components/Modal';
import OrderInfo from '../order/order_info';
import Info from '../../components/Info';
import Button, { BUTTON_POSITION } from '../../components/Button';
import { LINE_MODE } from '../../components/Field';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../defs/routes';

const ORDER_STATUS = {
  paid: BADGE_TYPE.SUCCESS,
  awaiting_payment: BADGE_TYPE.WARN,
  cancelled: BADGE_TYPE.ERROR,
  completed: BADGE_TYPE.INFO
};

const Refunds = () => {
  const {t} = useTranslation(['common', 'refund']);
  const navigate = useNavigate();

  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const [showOrderInfo, setShowOrderInfo] = useState(false);
  const [order, setOrder] = useState(null);

  const handleCloseOrderInfo = useCallback(() => {
    setShowOrderInfo(false);
    setOrder(null);
  }, []);

  const handleOpenOrderInfo = useCallback((order) => {
    setOrder(order);
    setShowOrderInfo(true);
  }, []);

  const handleOpenNewRefund = () => {
    navigate(ROUTES.NEW_REFUND, { replace: true });
  };

  useEffect(() => {
  }, []);

  const autocompleteHandler = async (query) => {
    return repository.list(API.order.autocomplete, { query }, Object).then((rows) => {
      let list = [];
      if (rows) {
        for (let i = 0; i < rows.length; ++i) {
          list.push({
            value: rows[i].value,
            title: `${rows[i].label}. ${t('refund:refunds.phone', { phone: Phone.Text({ phone: rows[i].meta.phone }) })}`
          });
        }
      }
      return list;
    }).catch((e) => {
      log.error(e);
      return Promise.reject(e);
    });
  };

  return (
    <div className="trip-app">
      <NavBar title={t('refund:refunds.title')} />
      <div className="trip-app-body">

        <Button.Panel>
          <Button position={BUTTON_POSITION.LEFT} onClick={handleOpenNewRefund}>{t('refund:button.create_refund')}</Button>
        </Button.Panel>

        <ReportFilter
          apiHandler={(reportParams) => userInfo.getMyOrders(reportParams.filters)}
          show={SHOW_MODE.ALWAYS}
          autosuggest={{
            key: 'client',
            apiHandler: autocompleteHandler
          }}
          placeholder={t('refund:refunds.filter')}
          output={(list) => (
            <Table cols={3}>
              {list.map((order) => (
                <Fragment key={order.id}>
                  <Table.Row>
                    <Table.Cell colspan={2} onClick={() => { handleOpenOrderInfo(order); }}>
                      <InfoDescription description={<>{t('refund:refunds.from_date')}<DateFormat.DateTime date={order.date}/></>}>
                        {t('refund:refunds.order', { order: order.number })}
                      </InfoDescription>
                    </Table.Cell>
                    <Table.Cell align={ALIGN_TABLE.RIGHT}>
                      <Badge type={ORDER_STATUS[order.status]}>{t(`refund:status.${order.status}`)}</Badge>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className="small">
                      {t('refund:refunds.excursion')}
                    </Table.Cell>
                    <Table.Cell colspan={2} className="small" align={ALIGN_TABLE.RIGHT}>
                      <Info>{order.excursion.title}</Info>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className="small">
                      {t('refund:refunds.departure')}
                    </Table.Cell>
                    <Table.Cell colspan={2} className="small" align={ALIGN_TABLE.RIGHT}>
                      <Info><DateFormat.DateTime date={order.schedule.date}/></Info>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row delim={true} />
                </Fragment>
              ))}
            </Table>
          )}
          filters={[
            // { key: 'number', title: t('sale:filter.order'), type: 'value.text' },
            { key: 'date', title: t('sale:filter.schedule.date'), type: 'value.date', mode: LINE_MODE.TWO },
            // { key: 'status', title: t('sale:filter.status'), type: 'badge', list: [
            //   { id: OrderStatus.AWAITING_PAYMENT, title: t('sale:status.awaiting_payment') },
            //   { id: OrderStatus.PAID, title: t('sale:status.paid') },
            //   { id: OrderStatus.CANCELLED, title: t('sale:status.cancelled') },
            //   { id: OrderStatus.COMPLETED, title: t('sale:status.completed') }
            // ] },
          ]}
        />
      </div>

      <Modal show={order && showOrderInfo} onHide={handleCloseOrderInfo}>
        <Modal.Header closeButton>
          {showOrderInfo && (
            <InfoDescription
              description={(
                <>
                  {t('refund:order_info.create')}
                  <DateFormat.DateTime date={order && order.date}/>
                </>
              )}
            >
              {t('refund:order_info.title', { order: order && order.number })}
            </InfoDescription>
          )}
        </Modal.Header>
        <Modal.Body>
          {showOrderInfo && ( /* re-create body*/
            <OrderInfo order={order} onNext={handleCloseOrderInfo} onClose={handleCloseOrderInfo} />
          )}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Refunds;
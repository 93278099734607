import { useState, useCallback, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import BackBar from '../../components/BackBar';
import QRCode from '../../components/QRCode';
import Button, { BUTTON_POSITION, BUTTON_TYPE } from '../../components/Button';
import Table, { ALIGN_TABLE } from '../../components/Table';
import InfoDescription from '../../components/InfoDescription';
import Price, { CURRENCY } from '../../components/Price';
import Badge, { BADGE_TYPE } from '../../components/Badge';
import Checkbox from '../../components/Checkbox';
import Modal from '../../components/Modal';
import TicketInput from './ticket_input';
import TicketView from './ticket_view';

const Place = ({ place, onNextStop }) => {
  const {t} = useTranslation(['common', 'route']);

  const [showTicketInputModal, setShowTicketInputModal] = useState(false);
  const [showTicketViewModal, setShowTicketViewModal] = useState(false);

  const [tickets, setTickets] = useState([]);
  const [ticket, setTicket] = useState({});

  const handleNextStop = () => {
    onNextStop && onNextStop();
  };

  const handleCloseTicketInputModal = useCallback(() => {
    setShowTicketInputModal(false);
  }, []);

  const handleCloseTicketViewModal = useCallback(() => {
    setShowTicketViewModal(false);
  }, []);

  useEffect(() => {
    let _tickets = [];

    _tickets.push({
      number: 123,
      phone: '+79001234567',
      name: 'Genry O`Nill',
      excursion: {
        title: 'Tour review'
      },
      amount: {
        adult: 1000,
        child: 800,
        noseat: 0
      },
      seller: {
        phone: '+79021234567',
        name: 'Robert Forman'
      },
      transport: {
        title: 'Bus',
        number: '231'
      },
      seats: [12, 34, 56],
      checked: false,
      date: new Date()
    });

    setTickets(_tickets);
    setTicket(_tickets[0]);
  }, []);

  return (
    <div>
      <Button variant={BUTTON_TYPE.SECONDARY} position={BUTTON_POSITION.NONE} onClick={handleNextStop}>{t('route:button.next_stop')}</Button>
      <div>
        {t('route:place.scan_qr')}
        <QRCode.Scanner />
      </div>
      <div className="input">
        {t('route:place.input_number')}
        <Table cols={2}>
          <Table.Row>
            <Table.Cell>
              <input className="form-control"></input>
            </Table.Cell>
            <Table.Cell>
              <Button variant={BUTTON_TYPE.SECONDARY} onClick={() => setShowTicketInputModal(true)}>{t('route:button.find')}</Button>
            </Table.Cell>
          </Table.Row>
        </Table>
      </div>

      <div className="header-line">{t('route:place.tickets')} - {place.title}</div>

      <Table cols={4}>
        {tickets.map((ticket) => (
          <Fragment key={ticket.number}>
            <Table.Row onClick={() => setShowTicketViewModal(true)}>
              <Table.Cell colspan={2}>
                <Checkbox.Result checked={true} />
                {t('route:place.ticket', { number: ticket.number })}
              </Table.Cell>
              <Table.Cell colspan={2} align={ALIGN_TABLE.RIGHT}>
                <Badge type={BADGE_TYPE.INFO}>{ticket.phone}</Badge>
              </Table.Cell>
            </Table.Row>
            <Table.Row onClick={() => setShowTicketViewModal(true)}>
              <Table.Cell colspan={2}>{ticket.excursion.title}</Table.Cell>
              <Table.Cell colspan={2} align={ALIGN_TABLE.RIGHT}>
                <Badge type={BADGE_TYPE.INFO}>{ticket.name}</Badge>
              </Table.Cell>
            </Table.Row>
            <Table.Row onClick={() => setShowTicketViewModal(true)}>
              <Table.Cell>
                <InfoDescription description={t('route:place.seat.adult')}>
                  <Price currency={CURRENCY.RUB} value={ticket.amount.adult} />
                </InfoDescription>
              </Table.Cell>
              <Table.Cell>
                <InfoDescription description={t('route:place.seat.child')}>
                  <Price currency={CURRENCY.RUB} value={ticket.amount.child} />
                </InfoDescription>
              </Table.Cell>
              <Table.Cell>
                <InfoDescription description={t('route:place.seat.noseat')}>
                  <Price currency={CURRENCY.RUB} value={ticket.amount.noseat} />
                </InfoDescription>
              </Table.Cell>
              <Table.Cell>
                <InfoDescription description={t('route:place.seats')}>
                  {ticket.seats.toString()}
                </InfoDescription>
              </Table.Cell>
            </Table.Row>
            <Table.Row delim={true} />
          </Fragment>
        ))}
      </Table>

      <Modal show={showTicketInputModal} onHide={handleCloseTicketInputModal}>
        <Modal.Header closeButton>
          <BackBar title={t('route:ticket_input.title', { number: ticket.number })}/>
        </Modal.Header>
        <Modal.Body>
          <TicketInput ticket={ticket}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseTicketInputModal}>{t('common:button.close')}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTicketViewModal} onHide={handleCloseTicketViewModal}>
        <Modal.Header closeButton>
          <BackBar title={t('route:ticket_view.title', { number: ticket.number })}/>
        </Modal.Header>
        <Modal.Body>
          <TicketView ticket={ticket}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseTicketViewModal}>{t('common:button.close')}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Place.propTypes = {
  onNextStop: PropTypes.func,
  place: PropTypes.any.isRequired
};

export default Place;
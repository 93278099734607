import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Alert from '../../components/Alert';
import Button, { BUTTON_TYPE } from '../../components/Button';
import Modal from '../../components/Modal';
import SelectPassengers from './select_passengers';
import SelectDate from './select_date';
import { OrderContext, STEPS } from '../../store/OrderProvider';
import { RepositoryContext } from '../../store/RepositoryProvider';
import { UserContext } from '../../store/UserProvider';
import Checkbox from '../../components/Checkbox';
import { Wishes } from '../../models/types';
import API from '../../server/api';
import DateFormat from '../../components/DateFormat';
import RepositoryModel from '../../components/RepositoryModel';
import log from '../../utils/logger';
import Select from '../../components/Select';

dayjs.extend(utc);
dayjs.extend(timezone);

const StepCommonInfo = () => {
  const {t} = useTranslation(['common', 'order']);

  const orderInfo = useContext(OrderContext);
  const userInfo = useContext(UserContext);
  const repository = useContext(RepositoryContext);

  const [excursions, setExcursions] = useState([]);
  const [subdivisions, setSubdivisions] = useState([]);
  const [prices, setPrices] = useState([]);
  const [places, setPlaces] = useState([]);
  const [errors, setErrors] = useState([]);

  const [excursion, setExcursion] = orderInfo.useState('excursion', null);
  const [schedule, setSchedule] = orderInfo.useState('schedule', null);
  
  const [commonExcursion, setCommonExcursion] = orderInfo.useState('common_excursion', userInfo.getOrderSession().excursion || '');
  const [commonSubdivision, setCommonSubdivision] = orderInfo.useState('common_subdivision', userInfo.getOrderSession().subdivision || '');
  const [commonDate, setCommonDate] = orderInfo.useState('common_date', userInfo.getOrderSession().date || '');
  const [commonTime, setCommonTime] = orderInfo.useState('common_time', userInfo.getOrderSession().time || '');
  const [commonPassengers, setCommonPassengers] = orderInfo.useState('common_passengers', userInfo.getOrderSession().passengers || []);
  const [commonWishes, setCommonWishes] = orderInfo.useState('common_wishes', userInfo.getOrderSession().wishes || []);
  const [commonPlace, setCommonPlace] = orderInfo.useState('common_place', userInfo.getOrderSession().place || '');
  const [commonPassport, setCommonPassport] = orderInfo.useState('common_passport', !!userInfo.getOrderSession().put_passport);
  const [paymentOrder] = orderInfo.useState('payment_order', userInfo.getOrderSession().order_id || '');

  const [showSelectPassengers, setShowSelectPassengers] = useState(false);
  const handleSelectPassengersClose = () => setShowSelectPassengers(false);
  const handleSelectPassengersShow = () => setShowSelectPassengers(true);

  const [showSelectDate, setShowSelectDate] = useState(false);
  const handleSelectDateClose = () => setShowSelectDate(false);
  const handleSelectDateShow = () => {
    setShowSelectDate(true);
  };


  const handleApplyDate = (day, time) => {
    setShowSelectDate(false);
    let date = DateFormat.Request({ date: day });
    setCommonDate(date);
    setCommonTime(time);
    fillSchedule({ date, time });
  };


  const handleChangeExcursion = (excursionId) => {
    fillSchedule({ excursionId });
  };


  const handleChangeSubdivision = (subdivisionId) => {
    setCommonSubdivision(subdivisionId);

    updateRoute(subdivisionId);
  };

  const updateRoute = (subdivisionId) => {
    const places = schedule ? schedule.getPlaces(subdivisionId).map((place) => ({ value: place.place.id, text: place.titleTime })) : [];
    setPlaces(places);
    const place = schedule.getPlaces(subdivisionId).find((place) => place.place.id === commonPlace);
    setCommonPlace(place ? place.place.id : (schedule && schedule.getPlaces(subdivisionId).length > 0 ? schedule.getPlaces(subdivisionId)[0].place.id : ''));
  };


  const handleCloseError = (uid) => {
    let _errors = errors;
    orderInfo.clearError('common_info', uid);

    if (uid) {
      let idx = _errors.findIndex(error => error.uid === uid);
      if (idx >= 0) {
        _errors.splice(idx, 1);
      }
    } else {
      _errors = [];
    }

    setErrors(_errors);
  };


  const fillSchedule = ({ date = commonDate, time = commonTime, excursionId = commonExcursion, placeId = commonPlace, subdivisionId = commonSubdivision }) => {
    date = date || commonDate;
    time = time || commonTime;
    excursionId = excursionId || commonExcursion;
    placeId = placeId || commonPlace;
    subdivisionId = subdivisionId || commonSubdivision;
    const scheduleId = userInfo.getOrderSession().schedule;

    let excursion = null;
    let excursions = [];
    let subdivision = null;
    let subdivisions = [];
    let prices = [];
    let places = [];
    let place = null;

    repository.request(API.schedule.schedule, { id: scheduleId }).then((schedule) => {
      setSchedule(schedule);

      if (schedule) {
        excursion = schedule.getExcursion(excursionId);
        excursions = schedule.excursions.map((excursion) => ({ value: excursion.excursion.id, text: excursion.excursion.title }));

        if (excursion) {
          subdivision = excursion.subdivisions.find((subdivision) => subdivision.subdivision.id === subdivisionId);
          subdivisions = excursion.subdivisions.map((subdivision) => ({ value: subdivision.subdivision.id, text: subdivision.subdivision.title }));

          if (subdivision) {
            prices = [...subdivision.prices];
            places = schedule.getPlaces(subdivisionId).map((place) => ({ value: place.place.id, text: place.titleTime }));
            place = schedule.getPlaces(subdivisionId).find((place) => place.place.id === commonPlace);
          }
        }
      }

      setExcursion(excursion);
      setCommonExcursion(excursion && excursion.excursion.id ? excursion.excursion.id : '');
      setExcursions(excursions);
      setSubdivisions(subdivisions);
      setCommonSubdivision(subdivision && subdivision.subdivision.id ? subdivision.subdivision.id : '');
      setPrices(prices);
      orderInfo.setPrices(prices);
      setCommonPlace('');
      setPlaces(places);
      setCommonPlace(place ? place.place.id : ((schedule && schedule.getPlaces(subdivisionId).length > 0) ? schedule.getPlaces(subdivisionId)[0].place.id : ''));

    }).catch((e) => {
      log.error('schedule', { id: scheduleId }, e);
      setExcursion(excursion);
      setCommonExcursion('');
      setExcursions(excursions);
      setSubdivisions(subdivisions);
      setCommonSubdivision('');
      setPrices(prices);
      orderInfo.setPrices(prices);
      setCommonPlace('');
      setPlaces(places);
      setCommonPlace('');
    });
  };


  const handleSelectPassengers = ({ passengers, wishes }) => {
    handleSelectPassengersClose();
    setCommonPassengers(passengers);
    setCommonWishes(wishes);
  };


  const handleWishes = (wish, checked) => {
    let orderWishes = userInfo.getOrderSession().wishes;
    let wishIdx = orderWishes.indexOf(wish);

    if (!checked) {
      if (wishIdx >= 0) {
        orderWishes.splice(wishIdx, 1);
      }
    } else {
      if (wishIdx === -1) {
        orderWishes.push(wish);
      }
    }

    setCommonWishes(orderWishes);
  };


  useEffect(() => {
    fillSchedule({
      date: userInfo.getOrderSession().date,
      time: userInfo.getOrderSession().time,
      scheduleId: userInfo.getOrderSession().schedule,
      excursionId: userInfo.getOrderSession().excursion,
      placeId: userInfo.getOrderSession().place,
      subdivisionId: userInfo.getOrderSession().subdivision
    });
  }, []);


  const validate = async (info) => {
    let errors = await orderInfo.validate(STEPS.common_info, info);
    setErrors(errors);
  };


  useEffect(() => {
    if (commonExcursion && commonSubdivision) {
      const info = {
        excursion: commonExcursion,
        subdivision: commonSubdivision,
        date: commonDate,
        time: commonTime,
        passengers: commonPassengers,
        wishes: commonWishes,
        place: commonPlace,
        put_passport: commonPassport,
        schedule: userInfo.getOrderSession().schedule
      };

      userInfo.setOrderSession(info);

      if (commonExcursion) {
        validate(info);
      }
    }
  }, [commonExcursion, commonSubdivision, commonDate, commonTime, commonPassengers, commonWishes, commonPlace, commonPassport]);


  return (
    <>
      <div>
        {errors.map((error, i) => (
          <Alert type={error.type} key={error.uid} title={error.title} dismissible={true} onClose={() => handleCloseError(error.uid)}>{error.message}</Alert>
        ))}

        {paymentOrder ? paymentOrder : '-'}

        <div className="input">
          <label className="field-label">{t('order:common_info.excursion')}</label>
          <Select
            value={commonExcursion || ''}
            onSelect={handleChangeExcursion}
            // onSelect={(value, target) => { handleChangeExcursion({ excursionId: value, scheduleId: target.querySelector('option:checked').getAttribute('data-schedule') }); }}
            options={excursions}
            disabled={!!paymentOrder}
          />
        </div>

        <div className="input">
          <label className="field-label">{t('order:common_info.subdivision')}</label>
          <Select
            value={commonSubdivision || ''}
            onSelect={handleChangeSubdivision}
            options={subdivisions}
            disabled={!!paymentOrder}
          />
        </div>

        {excursion && excursion.passportRequired && (
          <div className="input">
            <div>{t('order:common_info.passport_data')}</div>
            <Checkbox.Slide title={t('order:common_info.put_client')} checked={!commonPassport} onChange={(value, checked) => setCommonPassport(!checked)} disabled={!!paymentOrder}/>
          </div>
        )}

        <div className="input">
          <label className="field-label">{t('order:common_info.date_departure')}</label>
          <Button variant={BUTTON_TYPE.SECONDARY} onClick={handleSelectDateShow} disabled={!!paymentOrder}>
            <DateFormat.Day date={commonDate} />, {commonTime}
          </Button>
        </div>

        <div className="input">
          <label className="field-label">{t('order:common_info.passengers')}</label>
          {excursion && excursion.passportRequired && commonPassport && (
            <>
              {commonPassengers.reduce((count, passenger) => count + passenger.passports.reduce((count, passport) => count + (passport && passport.fullName && passport.number ? 1 : 0), 0), 0) === commonPassengers.reduce((count, passenger) => count + passenger.count, 0) ? (
                <span className="ready-note">{t('order:common_info.ready_passport')}</span>
              ) : (
                <span className="alert-note">{t('order:common_info.put_passport')}</span>
              )}
            </>
          )}
          <Button variant={BUTTON_TYPE.SECONDARY} onClick={handleSelectPassengersShow} disabled={!!paymentOrder}>
            {commonPassengers.map((passenger) => (
              <div className="passenger-price" key={passenger.excursion_price}>{passenger.count} <RepositoryModel type="Price" uid={passenger.price} render={(model) => model.title} /></div>
            ))}
          </Button>
          <Checkbox.Group>
            {Object.values(Wishes).map((wish) => (
              <Checkbox.Button key={wish} title={t(`order:wishes.${wish}`)} checked={commonWishes.indexOf(wish) >= 0} value={wish} onChange={handleWishes} disabled={!!paymentOrder}/>
            ))}
          </Checkbox.Group>
        </div>

        <div className="input">
          <label className="field-label">{t('order:common_info.seat')}</label>
          <Select
            value={commonPlace || ''}
            onSelect={setCommonPlace}
            empty={{ value: -1, text: t('order:common_info.none')}}
            options={places}
            disabled={!!paymentOrder}
          />
        </div>
      </div>

      <Modal show={showSelectPassengers} onHide={handleSelectPassengersClose}>
        <Modal.Header>{t('order:select_passengers.title')}</Modal.Header>
        <Modal.Body>
          {showSelectPassengers && (
            <SelectPassengers
              prices={prices}
              limits={{seats: (schedule ? schedule.limit.value - schedule.limit.cntSeats : 0)}}
              put_passport={commonPassport}
              passengers={commonPassengers}
              wishes={commonWishes}
              onApply={handleSelectPassengers}
              onCancel={handleSelectPassengersClose}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showSelectDate} onHide={handleSelectDateClose}>
        <Modal.Header>{t('order:select_date.title')}</Modal.Header>
        <Modal.Body>
          {showSelectDate && (
            <SelectDate onApply={handleApplyDate} onCancel={handleSelectDateClose}/>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

StepCommonInfo.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onError: PropTypes.func,
};

export default StepCommonInfo;